import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import FileUploadPersistRemoveControl from '@payaca/components/fileUploadPersistRemoveControl/FileUploadPersistRemoveControl';
import TextareaFieldFormatter, {
  ToolbarColourVariant,
} from '@payaca/components/textareaField/TextareaFieldFormatter';

import { useSelector } from '@/api/state';
import { actions as userActions, actions as usersActions } from '@/api/users';
import { useFormState } from '@/hooks/useFormState';
import { useAccount } from '@/utils/storeHooks';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import Card from '@payaca/components/plCard/Card';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import CompanySettingsTabBase from './CompanySettingsTabBase';

const acceptFileTypes = getAcceptedFileTypes(['document', 'image']);

type Props = {
  readOnly: boolean;
};

type FormState = {
  businessNotes: string;
  terms: {
    fileName: string;
    identifier: number;
  }[];
};

type FormValidationResult = Partial<
  Record<keyof FormState, FieldValidationResult>
>;

const CompanySettingsTerms: FC<Props> = ({ readOnly }: Props): JSX.Element => {
  const dispatch = useDispatch();

  const account = useAccount();
  const currentTerms = useSelector((state: any) => state.users.terms);

  useEffect(() => {
    dispatch(userActions.getAccountTerms(account.id));
  }, []);

  type FormState = {
    businessNotes: string;
  };

  const initialState: FormState = {
    businessNotes: account.businessNotes,
  };

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();

  const saveFormData = useMemo(
    () => async (state: FormState) => {
      clearDynamicFeedbackMessage.current?.();
      return new Promise<void>((resolve, reject) => {
        dispatch(
          usersActions.updateBusinessAccount(
            account.id,
            state,
            (err: unknown, response: any) => {
              if (err) {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: `We couldn't save your changes`,
                    isCancellable: true,
                    feedbackLevel: FeedbackLevel.ERROR,
                  });
                reject(err);
              } else {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: 'Your changes have been saved',
                    lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                    feedbackLevel: FeedbackLevel.SUCCESS,
                  });
                resolve();
              }
            }
          )
        );
      });
    },
    []
  );

  const { formState, updateFormFields, formValidationResult } = useFormState<
    FormState,
    FormValidationResult
  >(
    initialState,
    {},
    {
      autoSaveFn: saveFormData,
    }
  );

  const onPersistTerm = useCallback((file: File) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        usersActions.uploadTermsDocument(
          account.id,
          {
            file,
            fileName: file.name,
          },
          () => {
            dispatch(userActions.getAccountTerms(account.id));
            resolve();
          }
        )
      );
    });
  }, []);

  const onRemoveTerm = useCallback((fileIdentifier: string) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        usersActions.removeTermsDocument(account.id, fileIdentifier, () => {
          dispatch(userActions.getAccountTerms(account.id));
          resolve();
        })
      );
    });
  }, []);

  return (
    <CompanySettingsTabBase>
      <Card>
        <Card.Body className="flex flex-col gap-4">
          <h2>Terms</h2>
          {/* Terms text */}
          <TextareaFieldFormatter
            label="Terms of business"
            value={formState.businessNotes}
            name="businessNotes"
            onChange={updateFormFields}
            isDisabled={readOnly}
            placeholder="Text here will be added to the bottom of every quote &amp; invoice"
            toolbarColourVariant={ToolbarColourVariant.SECONDARY}
          />

          {/* Terms attachments */}
          <div>
            <FileUploadPersistRemoveControl
              label="Attach a file"
              acceptFileTypes={acceptFileTypes}
              allowMultipleUploads={true}
              enableDragAndDrop={true}
              persistFile={onPersistTerm}
              removePersistedFile={onRemoveTerm}
              persistedFiles={currentTerms.map((x: any) => ({
                fileName: x.fileName,
                identifier: x.id,
                thumbnailUrl: x.termUrl,
                downloadUrl: x.termUrl,
              }))}
            />
          </div>
        </Card.Body>
      </Card>
    </CompanySettingsTabBase>
  );
};

export default CompanySettingsTerms;
