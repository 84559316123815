import useCreateAddress from '@/api/mutations/addresses/useCreateAddress';
import useGetProjectMaterialsListSummaryItems, {
  MaterialsListMaterial,
} from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import MaterialsLeftToOrderList from '@/ui/components/createPurchaseOrderSidebar/components/MaterialsLeftToOrderList';
import MaterialsToOrderList from '@/ui/components/createPurchaseOrderSidebar/components/MaterialsToOrderList';
import PurchaseOrderDetails from '@/ui/components/createPurchaseOrderSidebar/components/PurchaseOrderDetails';
import { zodResolver } from '@hookform/resolvers/zod';
import Sidebar, {
  Props as SidebarProps,
} from '@payaca/components/plSidebar/Sidebar';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { clstx } from '@payaca/components/utils';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { requestPersistPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import { PersistPurchaseOrderRequestData } from '@payaca/store/materialsList/materialsListTypes';
import { PartialAddress } from '@payaca/types/locationTypes';
import { FC, useReducer, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';

export type TFormState = {
  supplierId?: number;
  // todo: minimise this type
  materials: (MaterialsListMaterial & {
    purchaseOrderQuantity: number;
  })[];
  dueAt?: Date;
  deliveryInfo: {
    deliveryAddressId?: number;
    newDeliveryAddress?: PartialAddress;
  };
  additionalNotes: string;
  showMaterialPrices: boolean;
};

const FORM_SCHEMA = z.object({
  supplierId: z.number(),
  materials: z.array(z.any()),
  deliveryInfo: z.any(),
  dueAt: z.date(),
  additionalNotes: z.string().optional(),
  showMaterialPrices: z.boolean(),
});

type FormStageState = {
  formStage: 'MATERIAL_LIST_MATERIALS' | 'PURCHASE_ORDER_DETAILS';
  transitionComplete: boolean;
};

type FormStageAction =
  | { type: 'GO_TO_MATERIAL_LIST_MATERIALS_STAGE' }
  | { type: 'GO_TO_PURCHASE_ORDER_DETAILS_STAGE' }
  | { type: 'TRANSITION_COMPLETE' }
  | { type: 'RESET' };

const formStageReducer = (
  state: FormStageState,
  action: FormStageAction
): FormStageState => {
  switch (action.type) {
    case 'GO_TO_MATERIAL_LIST_MATERIALS_STAGE':
      return {
        formStage: 'MATERIAL_LIST_MATERIALS',
        transitionComplete: false,
      };
    case 'GO_TO_PURCHASE_ORDER_DETAILS_STAGE':
      return { formStage: 'PURCHASE_ORDER_DETAILS', transitionComplete: false };
    case 'TRANSITION_COMPLETE':
      return { ...state, transitionComplete: true };
    case 'RESET':
      return defaultFormStageState;
    default:
      return state;
  }
};

const defaultFormStageState: FormStageState = {
  formStage: 'MATERIAL_LIST_MATERIALS',
  transitionComplete: true,
};

const CreateMaterialListMaterialsForm: FC<{
  projectId: number;
  materialListMaterials: MaterialsListMaterial[];
  onSubmit: (state: TFormState) => Promise<void>;
}> = (props) => {
  const { projectId, materialListMaterials, onSubmit } = props;

  const [formStageState, formStageDispatch] = useReducer(
    formStageReducer,
    defaultFormStageState
  );

  const [isSaving, setIsSaving] = useState(false);

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(FORM_SCHEMA),
    defaultValues: {
      materials: [],
      deliveryInfo: {},
      additionalNotes: '',
      showMaterialPrices: false,
    },
  });

  const handleSubmit = async (state: TFormState) => {
    setIsSaving(true);

    await onSubmit(state);

    setIsSaving(false);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        className={clstx(
          'absolute top-0 left-0 w-[150%] h-full grid grid-cols-3 transition-transform duration-500',
          formStageState.formStage === 'PURCHASE_ORDER_DETAILS'
            ? '-translate-x-1/3'
            : 'translate-x-0'
        )}
        onTransitionEnd={() => {
          formStageDispatch({ type: 'TRANSITION_COMPLETE' });
        }}
        onSubmit={formMethods.handleSubmit(handleSubmit, console.error)}
      >
        <MaterialsLeftToOrderList
          materialListMaterials={materialListMaterials}
          hideList={
            formStageState.formStage === 'PURCHASE_ORDER_DETAILS' &&
            formStageState.transitionComplete
          }
        />

        <MaterialsToOrderList
          materialListMaterials={materialListMaterials}
          formStage={formStageState.formStage}
          onAction={() => {
            if (formStageState.formStage === 'MATERIAL_LIST_MATERIALS') {
              formStageDispatch({
                type: 'GO_TO_PURCHASE_ORDER_DETAILS_STAGE',
              });
            } else {
              formStageDispatch({
                type: 'GO_TO_MATERIAL_LIST_MATERIALS_STAGE',
              });
            }
          }}
          actionDisabled={isSaving}
        />

        <PurchaseOrderDetails
          formStage={formStageState.formStage}
          projectId={projectId}
          hideList={
            formStageState.formStage === 'MATERIAL_LIST_MATERIALS' &&
            formStageState.transitionComplete
          }
          isSaving={isSaving}
        />
      </form>
    </FormProvider>
  );
};

export interface IProps extends SidebarProps {
  projectId: number;
  materialsListId: number;
  onSuccess?: (purchaseOrderId: number) => void;
}

const CreatePurchaseOrderSidebar: FC<IProps> = (props) => {
  const {
    isOpen,
    projectId,
    materialsListId,
    title = 'Create a Purchase Order',
    onSuccess,
    ...rest
  } = props;

  const { pushToast } = useToastContext();

  /**
   * Redux
   */
  const reduxDispatch = useDispatch();

  /**
   * Queries
   */
  const { materialsListOrderSummaryItems: materialsYetToOrder, isLoading } =
    useGetProjectMaterialsListSummaryItems(
      projectId,
      {
        statuses: ['TO_ORDER'],
      },
      PAGINATION_ARG_DEFAULTS
    );

  /**
   * Mutations
   */
  const { mutateAsync: mutateCreateAddress } = useCreateAddress();

  /**
   * Callbacks
   */
  const handleSubmit = async (state: TFormState) => {
    let deliveryAddressId: number | undefined =
      state.deliveryInfo.deliveryAddressId;

    if (state.deliveryInfo.newDeliveryAddress) {
      const {
        createAddress: { id: addressId },
      } = await mutateCreateAddress({
        line1: state.deliveryInfo.newDeliveryAddress.line1,
        line2: state.deliveryInfo.newDeliveryAddress.line2,
        city: state.deliveryInfo.newDeliveryAddress.city,
        postalCode: state.deliveryInfo.newDeliveryAddress.postcode,
        country: state.deliveryInfo.newDeliveryAddress.country,
      });

      if (addressId) {
        deliveryAddressId = +addressId;
      }
    }

    const requestData: PersistPurchaseOrderRequestData = {
      materialsListId,
      materialPurchaseIntents: state.materials.map((material) => ({
        materialsListMaterialId: +material.materialsListMaterial.id,
        materialQuantity: material.purchaseOrderQuantity,
      })),
      supplierId: state.supplierId,
      dueAt: state.dueAt,
      additionalNotes: state.additionalNotes,
      showMaterialPrices: state.showMaterialPrices,
      deliveryAddressId: deliveryAddressId,
    };

    await new Promise<number>((resolve, reject) => {
      reduxDispatch(
        requestPersistPurchaseOrder(
          requestData,
          (purchaseOrderId) => {
            resolve(purchaseOrderId);
          },
          () => {
            reject(new Error());
          }
        )
      );
    })
      .then((purchaseOrderId) => {
        onSuccess?.(purchaseOrderId);
      })
      .catch(() => {
        pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Something went wrong, please try again.',
        });
      });
  };

  return (
    <Sidebar isOpen={isOpen} size="lg" title={title} {...rest}>
      <Sidebar.Body className="relative">
        {isLoading || !materialsYetToOrder ? (
          <>Loading</>
        ) : (
          <CreateMaterialListMaterialsForm
            projectId={projectId}
            materialListMaterials={materialsYetToOrder.items}
            onSubmit={handleSubmit}
          />
        )}
      </Sidebar.Body>
    </Sidebar>
  );
};

export default CreatePurchaseOrderSidebar;
