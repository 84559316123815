import { FunctionComponent, useCallback, useMemo } from 'react';

import { useGetUser } from '@/api/queries/user/useGetUser';
import { UpdateUserInput } from '@/gql/graphql';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import { ColourInput } from '@payaca/components/plColourInput/ColourInput';
import Field from '@payaca/components/plField/Field';
import CurrencyInput from '@payaca/components/plInput/CurrencyInput';
import Modal from '@payaca/components/plModal/Modal';
import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import {
  DefaultSystemRoles,
  systemRoleHierarchyMap,
} from '@payaca/permissions/default-role.config';
import { User } from '@payaca/types/userTypes';

import { useUpdateUser } from '@/api/mutations/user/useUpdateUser';
import useGetMe from '@/api/queries/me/useGetMe';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import Button from '@payaca/components/plButton/Button';
import Select from '@payaca/components/plSelect/Select';
import { VALID_HEX_COLOUR } from '@payaca/constants/regexConstants';
import {
  getIsRequiredFieldValidator,
  getNumericalRangeFieldValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
export const UpdateUserModal: FunctionComponent<{
  userId?: User['id'];
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}> = ({ userId, isOpen, onClose, onSuccess }) => {
  const { data: userData } = useGetUser(userId);
  const { data: regionPreferences } = useGetMyRegionPreferences();
  const { mutateAsync: updateUserMutation, isLoading } = useUpdateUser();
  const { data: meData } = useGetMe();

  const initialFormData: Omit<UpdateUserInput, 'userId' | 'sortOrder'> =
    useMemo(() => {
      return {
        colour: userData?.user.colour,
        costPerHour: userData?.user.costPerHour?.value,
        systemRole: userData?.user.systemRole,
      };
    }, [userData]);

  const fieldValidators = useMemo(() => {
    return {
      colour: [
        getIsRequiredFieldValidator(),
        getRegexMatchFieldValidator(VALID_HEX_COLOUR, {
          customErrorMessage: 'Input must be a valid hexadecimal colour string',
        }),
      ],
      costPerHour: [getNumericalRangeFieldValidator(0)],
    };
  }, []);

  const roleOptions = Object.values(DefaultSystemRoles)
    .filter((role) => {
      if (!meData) return false;

      return (
        systemRoleHierarchyMap[role] <=
        systemRoleHierarchyMap[meData.me.user.systemRole as DefaultSystemRoles]
      );
    })
    .map((role) => ({
      label: role,
      value: role,
    }));

  const onSubmit = useCallback(
    async (formState: Omit<UpdateUserInput, 'userId' | 'sortOrder'>) => {
      const updateData: UpdateUserInput = {
        userId: userId?.toString() || '',
      };

      if (formState.colour !== userData?.user.colour) {
        updateData.colour = formState.colour;
      }

      if (formState.costPerHour !== userData?.user.costPerHour?.value) {
        updateData.costPerHour = formState.costPerHour;
      }

      if (formState.systemRole !== userData?.user.systemRole) {
        updateData.systemRole = formState.systemRole;
      }

      await updateUserMutation({
        ...updateData,
      });
      onSuccess?.();
    },
    [userId, onSuccess, userData?.user]
  );

  const shouldShowRoleField = useMemo(() => {
    if (!userData) return false;
    if (!meData) return false;

    if (userData.user.isPrimaryUser) return false;

    return (
      systemRoleHierarchyMap[userData.user.systemRole as DefaultSystemRoles] <=
      systemRoleHierarchyMap[meData.me.user.systemRole as DefaultSystemRoles]
    );
  }, [userData?.user, meData?.me.user]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Edit User'}>
      <ValidatedForm
        fieldValidators={fieldValidators}
        initialFormState={initialFormData}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          return (
            <>
              <Modal.Body>
                <div className="flex flex-col gap-4">
                  <Field
                    name="colour"
                    validationState={
                      validationState?.['colour']?.isValid === false &&
                      touchedState?.['colour']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['colour']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Colour</Field.Label>
                    <div className="flex items-center gap-4">
                      <ColourInput
                        value={formState.colour || ''}
                        onChange={(value) => onFieldChange({ colour: value })}
                        onTouch={() => onFieldTouch('colour')}
                      ></ColourInput>
                      <UserAvatar
                        sizeVariant={AvatarSizeVariant.SM}
                        user={{
                          firstName: userData?.user.firstName || '',
                          lastName: userData?.user.lastName || '',
                          emailAddress: userData?.user.email || '',
                          userColour: formState.colour || '',
                        }}
                      />
                    </div>
                  </Field>
                  {shouldShowRoleField && (
                    <Field name="systemRole">
                      <Field.Label>Role</Field.Label>
                      <Select
                        value={formState.systemRole as DefaultSystemRoles}
                        onChange={(value) => {
                          onFieldChange({ systemRole: value });
                          onFieldTouch('systemRole');
                        }}
                        options={roleOptions}
                      />
                    </Field>
                  )}
                  <PermissionGuard
                    renderIfHasPermissions={[
                      AccountsPermissions.SET_USER_COST_PER_HOUR,
                    ]}
                  >
                    <Field
                      name="costPerHour"
                      validationState={
                        validationState?.['costPerHour']?.isValid === false &&
                        touchedState?.['costPerHour']
                          ? {
                              isValid: false,
                              validationMessages:
                                validationState?.['costPerHour']?.errors,
                            }
                          : undefined
                      }
                    >
                      <Field.Label>Cost per hour</Field.Label>
                      <CurrencyInput
                        value={formState.costPerHour || 0}
                        onChange={(value) =>
                          onFieldChange({ costPerHour: value })
                        }
                        onBlur={() => onFieldTouch('costPerHour')}
                        currency={regionPreferences?.currency}
                      />
                    </Field>
                  </PermissionGuard>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Modal.Footer.Actions>
                  <Button
                    isProcessing={isLoading}
                    disabled={!isValid}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }
                      void onSubmit(formState);
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
      ></ValidatedForm>
    </Modal>
  );
};
