import { FC, useEffect, useRef } from 'react';

const ShadowDomRenderer: FC<{ html: string }> = ({ html }) => {
  const shadowHostRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shadowHostRef.current) {
      const shadowRoot = shadowHostRef.current.attachShadow({ mode: 'open' });

      const wrapper = document.createElement('div');
      wrapper.innerHTML = html;

      shadowRoot.appendChild(wrapper);
    }
  }, [html]);

  return <div ref={shadowHostRef}></div>;
};

export default ShadowDomRenderer;
