import {
  CustomFieldDefinition,
  CustomFieldType,
  CustomFieldValue,
} from '@payaca/custom-fields/types/index';
import { Account } from './accountTypes';
import { AutomationDataAccessType } from './automationDataAccessTypes';
import { Customer } from './customerTypes';
import { Deal } from './dealTypes';
import { Invoice } from './invoiceTypes';
import { JobContent } from './jobContentTypes';
import { JobPayment } from './jobPaymentTypes';
import { Job } from './jobTypesV2';
import { ListViewPage, SortDirection } from './listViewTypes';
import { ScheduledEvent } from './scheduledEventsTypes';
import { User } from './userTypes';

export enum AutomationEntityTypes {
  ESTIMATE = 'Estimate',
  QUOTE = 'Quote',
  INVOICE = 'Invoice',
  INVOICE_V2 = 'Invoice-v2',
  EVENT = 'Event',
  DEAL = 'Deal',
  PROPOSAL = 'Proposal',
  /*
  DOCUMENT = 'Document',
  FORM = 'Form',
  ITEM = 'Item',
  ITEM_GROUP = 'Item Group',
  USER = 'User',
  CUSTOMER = 'Customer',
  ADDRESS = 'Address',
  TAG = 'Tag',*/
}
export const automationEntityTypes = Object.values(AutomationEntityTypes);

export enum AutomationTriggerEvents {
  CREATED = 'Created',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  SENT = 'Sent',
  EXPIRED = 'Expired',
  PAYMENT_RECEIVED = 'Payment Received',
  FULLY_PAID = 'Fully Paid',
  ASSIGNED = 'Assigned',
  TAGGED = 'Tagged',
  ADDED_TO_PIPELINE = 'Added to pipeline',
  CALCULATED_PIPELINE_STAGE_CHANGED = 'Pipeline stage changed',
  EVENT_TASK_STATUS_CHANGED = 'Task status changed',

  // daily event options
  CALCULATED_PIPELINE_STAGE_CHANGED_YESTERDAY = 'Pipeline stage changed yesterday',
  CALCULATED_PIPELINE_STAGE_CHANGED_2_DAYS_AGO = 'Pipeline stage changed 2 days ago',
  CALCULATED_PIPELINE_STAGE_CHANGED_3_DAYS_AGO = 'Pipeline stage changed 3 days ago',
  CALCULATED_PIPELINE_STAGE_CHANGED_4_DAYS_AGO = 'Pipeline stage changed 4 days ago',
  CALCULATED_PIPELINE_STAGE_CHANGED_5_DAYS_AGO = 'Pipeline stage changed 5 days ago',
  CALCULATED_PIPELINE_STAGE_CHANGED_6_DAYS_AGO = 'Pipeline stage changed 6 days ago',
  CALCULATED_PIPELINE_STAGE_CHANGED_7_DAYS_AGO = 'Pipeline stage changed 7 days ago',

  DAILY_RUN = 'Scheduled Daily Run',
  REALTIME_RUN = 'Realtime Run',
  DUE_IN_7_DAYS = 'Due in 7 days',
  DUE_IN_3_DAYS = 'Due in 3 days',
  DUE_IN_1_DAY = 'Due in 1 day',
  DUE_TODAY = 'Due today',
  DUE_YESTERDAY = 'Due yesterday',
  DUE_3_DAYS_AGO = 'Due 3 days ago',
  DUE_7_DAYS_AGO = 'Due 7 days ago',
  EXPIRES_IN_28_DAYS = 'Expires in 28 days',
  EXPIRES_IN_21_DAYS = 'Expires in 21 days',
  EXPIRES_IN_14_DAYS = 'Expires in 14 days',
  EXPIRES_IN_7_DAYS = 'Expires in 7 days',
  EXPIRES_IN_3_DAYS = 'Expires in 3 days',
  EXPIRES_IN_1_DAY = 'Expires in 1 day',
  EXPIRES_TODAY = 'Expires today',
  EXPIRED_YESTERDAY = 'Expired yesterday',
  EXPIRED_3_DAYS_AGO = 'Expired 3 days ago',
  EXPIRED_7_DAYS_AGO = 'Expired 7 days ago',
  STARTS_IN_1_MONTH = 'Starts in 1 month',
  STARTS_IN_21_DAYS = 'Starts in 21 days',
  STARTS_IN_14_DAYS = 'Starts in 14 days',
  STARTS_IN_7_DAYS = 'Starts in 7 days',
  STARTS_IN_3_DAYS = 'Starts in 3 days',
  STARTS_IN_1_DAY = 'Starts in 1 day',
  STARTS_TODAY = 'Starts today',
  FINISHES_TODAY = 'Finishes today',
  FINISHED_YESTERDAY = 'Finished yesterday',
  FINISHED_3_DAYS_AGO = 'Finished 3 days ago',
  FINISHED_7_DAYS_AGO = 'Finished 7 days ago',
  FINISHED_1_MONTH_AGO = 'Finished 1 month ago',
  FINISHED_1_YEAR_AGO = 'Finished 1 year ago',
  // realtime event options
  STARTS_IN_1_HOUR = 'Starts in 1 hour',
  STARTS_NOW = 'Starts now',
  FINISHES_NOW = 'Finishes now',
  FINISHED_11_MONTHS_AGO = 'Finished 11 months ago',
  /*UPDATED = 'Updated',
  DUE = 'Due',
  OVERDUE = 'Overdue',
  DECLINED = 'Declined',
  EXPIRED = 'Expired',
  COMPLETED = 'Completed',
  STARTED = 'Started',
  DELETED = 'Deleted',
  ARCHIVED = 'Archived',*/
}
export const automationTriggerEvents = Object.values(AutomationTriggerEvents);

export interface AutomationCondition {
  id: number;
  automationId: BaseAutomation['id'];
  field: AutomationDataAccessType;
  operator: string;
  value: string;
  isActive: boolean;
}

export enum AutomationActionTypes {
  EMAIL_NOTIFICATION = 'E-mail Notification',
  DEAL_PROGRESSION = 'Deal Progression',
  SMS_NOTIFICATION = 'SMS Notification',
  CREATE_REPEAT_PROJECT = 'Create repeat Project',
  QUICKBOOKS_ADD_INVOICE = 'Send Invoice to Quickbooks',
  QUICKBOOKS_ADD_CUSTOMER = 'Send Customer to Quickbooks',
  XERO_ADD_INVOICE = 'Send Invoice to Xero',
  XERO_ADD_CUSTOMER = 'Send Customer to Xero',
}

export const automationActionTypes = Object.values(AutomationActionTypes);

export type ActionConfigHelperType = {
  [AutomationActionTypes.EMAIL_NOTIFICATION]: EmailNotificationActionConfig;
  [AutomationActionTypes.SMS_NOTIFICATION]: SMSActionConfig;
  [AutomationActionTypes.DEAL_PROGRESSION]: DealProgressionActionConfig;
  [AutomationActionTypes.CREATE_REPEAT_PROJECT]: void;
  [AutomationActionTypes.QUICKBOOKS_ADD_INVOICE]: void;
  [AutomationActionTypes.QUICKBOOKS_ADD_CUSTOMER]: void;
  [AutomationActionTypes.XERO_ADD_CUSTOMER]: void;
  [AutomationActionTypes.XERO_ADD_INVOICE]: void;
};

export interface AutomationAction<TActionType extends AutomationActionTypes> {
  id: number;
  automationId: BaseAutomation['id'];
  title: string;
  type: TActionType;
  config: ActionConfigHelperType[TActionType];
  uploadIds: number[];
}

export interface BaseAutomation {
  updatedAt: Date;
  createdAt: Date;
  id: number;
  accountId: number;
  title: string;
  entityType: AutomationEntityTypes;
  triggerEvent: AutomationTriggerEvents;
  isActive: boolean;
  isSystem: boolean;
  conditions: AutomationCondition[];
  defaultAutomationDefinitionPublicId?: DefaultAutomationDefinition['publicId'];
}

export interface AutomationTemplate {
  title: string;
  type: AutomationTemplateType;
}

export enum AutomationTemplateType {
  QUOTE_EXPIRING_REMINDER_DAY_OF = 'quote-expiring-reminder-day-of',
  QUOTE_EXPIRING_REMINDER_1_DAY_PRIOR = 'quote-expiring-reminder-1-day-prior',
  QUOTE_EXPIRING_REMINDER_3_DAYS_PRIOR = 'quote-expiring-reminder-3-days-prior',
  QUOTE_EXPIRING_REMINDER_7_DAYS_PRIOR = 'quote-expiring-reminder-7-days-prior',

  INVOICE_PAYMENT_REMINDER_7_DAYS_LATE = 'invoice-payment-reminder-7-days-late',
  INVOICE_PAYMENT_REMINDER_3_DAYS_LATE = 'invoice-payment-reminder-3-days-late',
  INVOICE_PAYMENT_REMINDER_DAY_OF = 'invoice-payment-reminder-day-of',
  INVOICE_PAYMENT_REMINDER_3_DAYS_PRIOR = 'invoice-payment-reminder-3-days-prior',

  EVENT_REMINDER_CUSTOMER_DAY_OF = 'event-reminder-customer-day-of',
  EVENT_REMINDER_CUSTOMER_1_DAY_PRIOR = 'event-reminder-customer-1-day-prior',
  EVENT_REMINDER_CUSTOMER_3_DAYS_PRIOR = 'event-reminder-customer-3-days-prior',
  EVENT_REMINDER_CUSTOMER_7_DAYS_PRIOR = 'event-reminder-customer-7-days-prior',
}
export interface Automation<T extends AutomationActionTypes>
  extends BaseAutomation {
  actions: AutomationAction<T>[];
}

export enum SortBy {
  TITLE = 'title',
  ENTITY_TYPE = 'entityType',
  TRIGGER_EVENT = 'triggerEvent',
  UPDATED_AT = 'updatedAt',
}

export enum ColumnType {
  TITLE = 'Deal Reference',
  ENTITY_TYPE = 'Type',
  TRIGGER_EVENT = 'Trigger Event',
  CONDITIONS = 'Conditions',
  ACTIONS = 'Actions',
}

export interface GetListedAutomationsRequestData {
  pageSize: number;
  pageNumber: number;
  searchTerm?: string;
  sortBy: SortBy;
  sortDirection: SortDirection;
  entityTypes?: AutomationEntityTypes[];
  triggerEvents?: AutomationTriggerEvents[];
  actionTypes?: AutomationActionTypes[];
}

export type DefaultAutomationDefinition = {
  id: string;
  publicId: string;
  title: string;
  description?: string;
  automationConfig: Pick<
    BaseAutomation,
    'title' | 'entityType' | 'triggerEvent'
  > & {
    conditions: Pick<AutomationCondition, 'value' | 'field' | 'operator'>[];
    defaultActions?: Pick<
      AutomationAction<AutomationActionTypes>,
      'type' | 'config'
    >[];
  };
  conditionValueCustomisationConfig: {
    conditionIndex: number;
    label: string;
    description?: string;
    isRequired?: boolean;
  }[];
  sortOrder: number;
};

export type PublicDefaultAutomationDefinition = Omit<
  DefaultAutomationDefinition,
  'id'
>;

export type HydratedDefaultAutomationDefinition<
  T extends AutomationActionTypes,
> = DefaultAutomationDefinition & {
  automation?: Automation<T>;
};

export type PublicHydratedDefaultAutomationDefinition<
  T extends AutomationActionTypes,
> = Omit<HydratedDefaultAutomationDefinition<T>, 'id'>;

export interface AutomationQueueMessageActionPayload<
  T extends AutomationActionTypes,
> {
  automationAction: AutomationAction<T>;
  automationEntityData: AutomationEntityDataClass;
  entityType: AutomationEntityTypes;
  entityId: number;
  triggerMetadata: EntityAutomationQueueMessageEventPayload['metadata'];
}

export interface AutomationQueueMessage {
  message: {
    data: string;
  };
}

export interface ScheduledAutomationQueueMessageEventPayload {
  triggerEvent: AutomationTriggerEvents;
}

export interface EntityAutomationQueueMessageEventPayload
  extends ScheduledAutomationQueueMessageEventPayload {
  entityType: AutomationEntityTypes;
  // not set in case of 'realtime' (i.e no explicit trigger, calculated on the fly)
  data: {
    accountId: number;
    entityId: number;
  };
  metadata: Record<string, any> & {
    triggeredAt: Date;
  };
}

/* CONFIG FOR EACH ACTION TYPE */
export interface EmailNotificationActionConfig {
  sendToEmail:
    | AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS
    | AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_EMAILADDRESS_ARRAY
    | AutomationDataAccessType.SCHEDULEDEVENT_LOCATION_CONTACTS_EMAILADDRESS_ARRAY
    | AutomationDataAccessType.DEAL_SITEADDRESSES_CONTACTS_EMAILADDRESS_ARRAY
    | AutomationDataAccessType.USER_EMAILADDRESS
    | AutomationDataAccessType.CUSTOM_EMAILADDRESS;
  customEmail?: string;
  emailTemplateId?: number;
  ccEmails?: string;
  bccEmails?: string;
  emailBody: string;
  emailSubject: string;
  emailVariables?: string[];
  sendACopy?: boolean;
  emailAttachments?: {
    url: string;
    fileName: string;
    mimeType: string;
    fileSize: number;
  }[];
}

export interface SMSActionConfig {
  sendToNumber:
    | AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER
    | AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_TELEPHONENUMBER_ARRAY
    | AutomationDataAccessType.CUSTOM_TELEPHONENUMBER
    | AutomationDataAccessType.DEAL_SITEADDRESSES_CONTACTS_TELEPHONENUMBER_ARRAY;
  customNumber?: number;
  sendFromNumber?: number;
  messageBody: string;
  messageVariables?: string[];
}

// add comment to force re-version

export interface DealProgressionActionConfig {
  targetStage: string;
}

export interface AutomationEntityData {
  account: Account;

  dealId?: number;
  customerId?: number;
  jobId?: number;
  scheduledEventId?: number;
  userId?: number;
  invoiceId?: number;

  deals: {
    [key: number]: Deal & {
      customFields?: Record<string, AutomationCustomFieldData<CustomFieldType>>;
    };
  };
  customers: { [key: number]: Customer };
  scheduledEvents: { [key: number]: ScheduledEvent };
  users: { [key: number]: User };
  jobs: { [key: number]: Job };
  invoices: { [key: number]: Invoice };
  invoiceClientUrls: { [key: number]: string };
  jobPayments: { [key: number]: JobPayment };
  jobContents: { [key: number]: JobContent };
  jobClientUrls: { [key: number]: string };
}

export type AutomationCustomFieldData<T extends CustomFieldType> = {
  identifier: CustomFieldDefinition<T>['identifier'];
  type: T;
  value?: CustomFieldValue<T>['value'];
};

export class AutomationEntityDataClass implements AutomationEntityData {
  constructor(automationEntityData?: AutomationEntityData) {
    if (automationEntityData) {
      this.account = automationEntityData?.account;

      this.dealId = automationEntityData.dealId;
      this.customerId = automationEntityData.customerId;
      this.userId = automationEntityData.userId;
      this.scheduledEventId = automationEntityData.scheduledEventId;
      this.jobId = automationEntityData.jobId;
      this.invoiceId = automationEntityData.invoiceId;

      this.deals = automationEntityData.deals;
      this.customers = automationEntityData.customers;
      this.users = automationEntityData.users;
      this.scheduledEvents = automationEntityData.scheduledEvents;
      this.jobs = automationEntityData.jobs;
      this.jobPayments = automationEntityData.jobPayments;
      this.jobContents = automationEntityData.jobContents;
      this.jobClientUrls = automationEntityData.jobClientUrls;
      this.invoices = automationEntityData.invoices;
      this.invoiceClientUrls = automationEntityData.invoiceClientUrls;
    }
  }

  account!: Account;

  dealId?: number;
  customerId?: number;
  userId?: number;
  jobId?: number;
  scheduledEventId?: number;
  invoiceId?: number;

  deals: {
    [key: number]: Deal & {
      customFields?: Record<string, AutomationCustomFieldData<CustomFieldType>>;
    };
  } = {};
  customers: { [key: number]: Customer } = {};
  scheduledEvents: { [key: number]: ScheduledEvent } = {};
  users: { [key: number]: User } = {};
  jobs: { [key: number]: Job } = {};
  jobPayments: { [key: number]: JobPayment } = {};
  jobContents: { [key: number]: JobContent } = {};
  jobClientUrls: { [key: number]: string } = {};
  invoices: { [key: number]: Invoice } = {};
  invoiceClientUrls: { [key: number]: string } = {};

  public getDealById(dealId: number) {
    return this.deals[dealId];
  }
  public getDeal() {
    return this.dealId ? this.getDealById(this.dealId) : undefined;
  }

  public getCustomerById(customerId: number) {
    return this.customers[customerId];
  }
  public getCustomer() {
    return this.customerId ? this.getCustomerById(this.customerId) : undefined;
  }

  public getScheduledEventById(scheduledEventId: number) {
    return this.scheduledEvents[scheduledEventId];
  }
  public getScheduledEvent() {
    return this.scheduledEventId
      ? this.getScheduledEventById(this.scheduledEventId)
      : undefined;
  }

  public getUserById(userId: number) {
    return this.users[userId];
  }
  public getUser() {
    return this.userId ? this.getUserById(this.userId) : undefined;
  }

  public getJobById(jobId: number) {
    return this.jobs[jobId];
  }
  public getJob() {
    return this.jobId ? this.getJobById(this.jobId) : undefined;
  }

  public getInvoiceById(invoiceId: number) {
    return this.invoices[invoiceId];
  }
  public getInvoice() {
    return this.invoiceId ? this.getInvoiceById(this.invoiceId) : undefined;
  }
}

export type UpdateDefaultAutomationData<T extends AutomationActionTypes> = {
  isActive: boolean;
  conditions: {
    conditionIndex: DefaultAutomationDefinition['conditionValueCustomisationConfig'][number]['conditionIndex'];
    value: AutomationCondition['value'];
  }[];
  action: Pick<AutomationAction<T>, 'type' | 'config' | 'uploadIds'>;
};

export type ListedAutomation = Automation<AutomationActionTypes>;
export interface ListedAutomationsListViewPage
  extends ListViewPage<ListedAutomation> {
  totalUnfilteredItemCount: number;
}
