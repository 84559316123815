import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import Card, { CardProps } from './Card';

const CardButton: FC<PropsWithChildren<CardProps<'button'>>> = (props) => {
  const { className, children, ...rest } = props;

  return (
    <Card
      as="button"
      className={twMerge(
        'block w-full p-0 text-left hover:cursor-pointer enabled:hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50 aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
        className
      )}
      type="button"
      {...rest}
    >
      {children}
    </Card>
  );
};

export default Object.assign(CardButton, { ...Card });
