import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
// @ts-ignore
import { Lightbox } from 'react-modal-image';

import { DealFileUploadModal } from '@/ui/components/dealFileUploadModal/DealFileUploadModal';
import PLButton from '@payaca/components/plButton/Button';
import LinkButton from '@payaca/components/plButton/LinkButton';
import Modal from '@payaca/components/plModal/Modal';

import * as documentActions from '@payaca/store/documents/documentActions';
import * as listedFilesActions from '@payaca/store/listedFiles/listedFilesActions';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';

import { useDeal, useListedFilesForDeal } from '@payaca/store/hooks/appState';

import { ListedFile, ListedFileType } from '@payaca/types/listedFileTypes';

import { useUserHasPermission } from '@/hooks/usePermissions';
import RenameFileModal from '@/ui/components/dealFiles/components/RenameFileModal';
import ShareModal from '@/ui/components/dealFiles/components/ShareModal';
import { getUntitledIconForFileType } from '@/utils/files';
import { useAccount } from '@/utils/storeHooks';
import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import {
  TTableBulkAction,
  TTableRowAction,
  TTableShouldDisableRow,
} from '@payaca/components/plAdvancedTable/AdvancedTable';
import Badge from '@payaca/components/plBadge/Badge';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { InputIcon } from '@payaca/components/plInput/RawInput';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import {
  getReadableListedFileType,
  ListedFileTypeMap,
} from '@payaca/helpers/listedFileHelper';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { dedupe } from '@payaca/shared-isomorphic/arrays';
import UntitledIcon from '@payaca/untitled-icons';
import { downloadPdfData } from '@payaca/utilities/fileUtilities';
import { debounce } from 'lodash-es';
import moment from 'moment-timezone';
import { LinkProps } from 'react-router-dom';
import { useMyAccountHasFeature } from '../../../api/queries/me/useGetMyAccountFeatures';
import './DealFiles.sass';

const DisplayFilterType: { [key in ListedFileType]: string } = {
  image: 'Images',
  video: 'Videos',
  pdf: 'PDFs',
  form: 'Forms',
  other: 'Other',
};

type GetListedFilesForDealRequestData = {
  searchTerm: string;
  fileType: ListedFileType[];
};
interface Props {
  dealId: number;
  onDealUpdateSuccess: (updatedJobIds?: number[]) => void;
}
const DealFiles: FC<Props> = ({
  dealId,
  onDealUpdateSuccess,
}: Props): JSX.Element | null => {
  const account = useAccount();
  const dispatch = useDispatch();
  const history = useHistory();
  const deal = useDeal(dealId);
  const {
    listedFiles = [],
    isGettingDealFiles,
    isUnsharingFilesForDeal,
  } = useListedFilesForDeal(dealId);

  const canShareDealFiles = useUserHasPermission({
    permissions: [DealsPermissions.SHARE_DEAL_FILES],
  });
  const canUnshareDealFiles = useUserHasPermission({
    permissions: [DealsPermissions.UNSHARE_DEAL_FILES],
  });
  const accountHasAiFeature = useMyAccountHasFeature('useAi');

  const [showFileUploadModal, setShowFileUploadModal] =
    useState<boolean>(false);
  const [creatingFormInstanceId, setCreatingFormInstanceId] =
    useState<number>();
  const [filesToDelete, setFilesToDelete] = useState<ListedFile[]>([]);
  const [filesToShare, setFilesToShare] = useState<ListedFile[]>([]);
  const [filesToUnshare, setFilesToUnshare] = useState<ListedFile[]>([]);
  const [fileToRename, setFileToRename] = useState<ListedFile | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [
    getListedFilesForDealRequestData,
    setGetListedFilesForDealRequestData,
  ] = useState<GetListedFilesForDealRequestData>({
    searchTerm: '',
    fileType: [],
  });
  const [enlargeImage, setEnlargeImage] = useState<{
    url: string;
    fileName: string;
  } | null>(null);
  const shortDateRegionalFormat = useMemo(
    () =>
      getInternationalMomentDateFormatByRegion(
        DateFormats.SHORT,
        account.region
      ),
    [account.region]
  );

  useEffect(() => {
    getListedFilesForDeal(getListedFilesForDealRequestData);
  }, [dealId, getListedFilesForDealRequestData]);

  const getListedFilesForDeal = (data: GetListedFilesForDealRequestData) => {
    dispatch(
      listedFilesActions.getListedFilesForDeal.request({
        dealId,
        searchTerm: data.searchTerm,
        fileType: data.fileType,
      })
    );
  };

  const tableBulkActions = useMemo(() => {
    const actions: TTableBulkAction<ListedFile>[] = [];

    if (canShareDealFiles) {
      actions.push({
        label: 'Share',
        onClick: (rows) => setFilesToShare(rows),
        variant: EBtnVariant.White,
        colour: EBtnColour.Black,
        disabledTooltip: 'Select a file to share',
      });
    }

    return actions;
  }, [canShareDealFiles, canUnshareDealFiles]);

  const tableRowActions = useCallback(
    (row: ListedFile) => {
      const actions: TTableRowAction<ListedFile>[] = [];

      if (row.canRename) {
        actions.push({
          label: 'Rename',
          onClick: setFileToRename,
        });
      }

      if (row.canShare && canShareDealFiles) {
        actions.push({
          label: 'Share',
          onClick: (file) => setFilesToShare([file]),
        });
      }

      if (row.canShare && canUnshareDealFiles && row.isShared) {
        actions.push({
          label: 'Unshare',
          onClick: (file) => setFilesToUnshare([file]),
        });
      }

      if (row.canDelete) {
        actions.push({
          label: 'Delete',
          onClick: (file) => setFilesToDelete([file]),
        });
      }

      return actions;
    },
    [canShareDealFiles, canUnshareDealFiles]
  );

  const shouldDisableRow = useCallback<TTableShouldDisableRow<ListedFile>>(
    (row) => {
      return {
        disabled: !row.canShare,
        tooltip:
          row.entity !== 'form_instances'
            ? `Files shared via Proposals and Invoices cannot be ${
                canUnshareDealFiles ? 'unshared or' : ''
              } deleted as they have already been sent to the customer.`
            : undefined,
      };
    },
    [canUnshareDealFiles]
  );

  const canAddDealFile = useMemo(() => !deal?.archivedAt, [deal]);

  /**
   * Handles unsharing files for deal
   */
  const handleUnshareFiles = useCallback(() => {
    if (filesToUnshare.length === 0) return;

    dispatch(
      listedFilesActions.unshareFilesForDeal.request({
        dealId,
        // @ts-expect-error
        files: filesToUnshare.map((file) => ({
          type: file.entity,
          id: file.entityId.toString(),
        })),
        callback: () => {
          setFilesToUnshare([]);
        },
      })
    );
  }, [filesToUnshare, dispatch, dealId]);

  /**
   * Handles deleting files for deal
   */
  const handeDeleteFiles = useCallback(async () => {
    if (filesToDelete.length === 0) return;
    setIsDeleting(true);

    await Promise.allSettled(
      filesToDelete.map(
        (file) =>
          new Promise<void>((resolve, reject) => {
            let id;
            if (file.entity === 'documents') {
              id = file.entityId;
              dispatch(documentActions.requestRemoveDocument(id, resolve));
            } else if (file.entity === 'form_instances') {
              id = file.meta?.documentId;
              dispatch(documentActions.requestRemoveDocument(id, resolve));
            } else if (file.entity === 'uploads') {
              dispatch(
                uploadsActions.requestDeleteUpload(
                  file.entityId,
                  () => {
                    resolve();
                  },
                  true
                )
              );
            } else {
              resolve();
              return;
            }
          })
      )
    );

    onDealUpdateSuccess();
    getListedFilesForDeal(getListedFilesForDealRequestData);
    setIsDeleting(false);
    setFilesToDelete([]);
  }, [filesToDelete, dispatch, dealId, onDealUpdateSuccess]);

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<ListedFile[]>([]);

  const filteredListedFiles = useMemo(() => {
    if (selectedFilters.length) {
      const filtered = selectedFilters.reduce((acc, filterId) => {
        const filter = Object.keys(ListedFileTypeMap).find(
          (i) => i === filterId
        );

        if (!filter) {
          return acc;
        }

        acc.push(...listedFiles.filter((i) => i.fileType === filter));

        return acc;
      }, [] as ListedFile[]);

      return dedupe<ListedFile, number>((i) => i.entityId)(filtered);
    }

    return listedFiles;
  }, [selectedFilters, listedFiles]);

  const onSearchChange = useMemo(() => {
    return debounce((searchTerm: string) => {
      setGetListedFilesForDealRequestData((data) => ({
        ...data,
        searchTerm: searchTerm,
      }));
    }, 300);
  }, []);

  if (!dealId) {
    return null;
  }

  return (
    <>
      <div className="@container">
        <ManageableItemsList>
          <ManageableItemsList.HeaderBar
            heading={'Files'}
            subHeading={
              'Upload files, images, videos, PDFs and forms. These can be shared with your customers.'
            }
            buttons={
              <>
                <LinkButton
                  className="md:whitespace-nowrap"
                  to={`/forms?dealId=${dealId}`}
                  variant={EBtnVariant.White}
                  colour={EBtnColour.Black}
                  size={EBtnSize.Small}
                >
                  Choose from template
                </LinkButton>
                <PLButton
                  disabled={!canAddDealFile}
                  className="md:whitespace-nowrap"
                  onClick={() => setShowFileUploadModal(true)}
                  size={EBtnSize.Small}
                >
                  Add files
                </PLButton>
              </>
            }
          />
          <ManageableItemsList.ActionBar>
            <ConditionalWrapper
              Wrapper={Tooltip}
              condition={accountHasAiFeature}
              wrapperProps={{
                tooltipContent: 'Search by what you see in the images',
                className: 'mr-auto',
              }}
            >
              <ManageableItemsList.ActionBar.SearchInput
                onChange={onSearchChange}
                leadingElement={
                  accountHasAiFeature ? (
                    <InputIcon name="star-06.3" />
                  ) : undefined
                }
                placeholder={accountHasAiFeature ? 'Search with AI' : 'Search'}
              />
            </ConditionalWrapper>
            <ManageableItemsList.ActionBar.BasicFilter
              options={Object.keys(ListedFileTypeMap).map((fileType) => ({
                value: fileType,
                label: DisplayFilterType[fileType as ListedFileType],
              }))}
              value={selectedFilters}
              onChange={setSelectedFilters}
            />
            {tableBulkActions.map((action, index) => (
              <ManageableItemsList.ActionBar.GlobalAction
                key={index}
                tooltipContent={action.disabledTooltip}
                enabled={selectedRows.length === 0}
                onClick={() => {
                  action.onClick(selectedRows);
                }}
                disabled={selectedRows.length === 0}
              >
                {action.label}
              </ManageableItemsList.ActionBar.GlobalAction>
            ))}
          </ManageableItemsList.ActionBar>
          <ManageableItemsList.Table
            isLoading={isGettingDealFiles && !listedFiles.length}
            uniqueKey="entityId"
            items={filteredListedFiles}
            enableSelectAll={!!tableBulkActions?.length}
            selectedItems={selectedRows}
            setSelectedItems={
              tableBulkActions?.length ? setSelectedRows : undefined
            }
            shouldDisableItem={shouldDisableRow}
            itemActions={tableRowActions}
          >
            <ManageableItemsList.Table.Column<ListedFile, 'thumbnailUrl'>
              header="Media"
              field="thumbnailUrl"
              render={(thumbnailUrl, row) => {
                if (thumbnailUrl) {
                  return (
                    <button
                      className="cursor-pointer bg-transparent p-0"
                      onClick={() =>
                        setEnlargeImage({
                          fileName: row.name,
                          url: row.url || '',
                        })
                      }
                    >
                      <img
                        className="h-auto w-12"
                        src={thumbnailUrl}
                        alt="File preview"
                      />
                    </button>
                  );
                }

                return (
                  <UntitledIcon
                    name={getUntitledIconForFileType(row.fileType)}
                    className="h-7 w-7"
                  />
                );
              }}
            />
            <ManageableItemsList.Table.Column<ListedFile, 'name'>
              header="Name"
              field="name"
              render={(name, row) => {
                const Text = (
                  <Tooltip
                    tooltipContent={name}
                    className="@screen-lg:max-w-[400px] max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap text-left"
                  >
                    {name}
                  </Tooltip>
                );

                if (row.entity === 'job_pdf_data' && row.url) {
                  return (
                    <PLButton
                      variant={EBtnVariant.LinkInline}
                      onClick={() =>
                        downloadPdfData(row.url!, row?.name || 'Document')
                      }
                    >
                      {Text}
                    </PLButton>
                  );
                }

                if (row.fileType === 'form') {
                  let to: LinkProps['to'] | null = null;

                  if (row.meta?.formCompletedAt) {
                    to = `/documents/${row.meta?.documentId}`;
                  } else if (row.meta?.formPreviewToken) {
                    to = `/forms/${row.meta?.formPreviewToken}/menu?dealId=${dealId}`;
                  } else {
                    return (
                      <PLButton
                        variant={EBtnVariant.LinkInline}
                        isProcessing={creatingFormInstanceId === row.entityId}
                        onClick={() => {
                          setCreatingFormInstanceId(row.entityId);

                          dispatch(
                            documentActions.createFormInstanceForDocument.request(
                              {
                                documentId: row.meta?.documentId,
                                callback: (
                                  formInstancePreviewToken: string
                                ) => {
                                  setCreatingFormInstanceId(undefined);
                                  history.push(
                                    `/forms/${formInstancePreviewToken}/menu?dealId=${dealId}`
                                  );
                                },
                                onErrorCallback: () => {
                                  setCreatingFormInstanceId(undefined);
                                },
                              }
                            )
                          );
                        }}
                      >
                        {Text}
                      </PLButton>
                    );
                  }

                  if (!to) return name;

                  return (
                    <LinkButton variant={EBtnVariant.LinkInline} to={to}>
                      {Text}
                    </LinkButton>
                  );
                }

                if (row.fileType === 'image') {
                  return (
                    <PLButton
                      variant={EBtnVariant.LinkInline}
                      onClick={() =>
                        setEnlargeImage({
                          fileName: row.name,
                          url: row.url || '',
                        })
                      }
                    >
                      {Text}
                    </PLButton>
                  );
                }

                return (
                  <a href={row.url} target="_blank" rel="noopener noreferrer">
                    {Text}
                  </a>
                );
              }}
            />
            <ManageableItemsList.Table.Column header="Source" field="source" />
            <ManageableItemsList.Table.Column
              header="Created"
              field="createdAt"
              render={(value) => moment(value).format(shortDateRegionalFormat)}
            />
            <ManageableItemsList.Table.Column
              header="Type"
              field="fileType"
              render={(value) => getReadableListedFileType(value)}
            />
            <ManageableItemsList.Table.Column<ListedFile, 'isShared'>
              header="Status"
              field="isShared"
              render={(isShared, row) => {
                if (
                  row.entity === 'form_instances' &&
                  row.meta &&
                  !row.meta.formCompletedAt
                ) {
                  return (
                    <Badge variant="soft" colour="blue">
                      Draft
                    </Badge>
                  );
                }

                if (isShared) {
                  return (
                    <Badge variant="soft" colour="teal">
                      Shared
                    </Badge>
                  );
                }

                return null;
              }}
            />
          </ManageableItemsList.Table>
        </ManageableItemsList>
        {/* Upload Files Modal */}
        <DealFileUploadModal
          dealId={dealId}
          isOpen={showFileUploadModal}
          onClose={() => setShowFileUploadModal(false)}
          onUploadSuccess={() => {
            onDealUpdateSuccess();
            getListedFilesForDeal(getListedFilesForDealRequestData);
            setShowFileUploadModal(false);
          }}
        />

        <ShareModal
          dealId={dealId}
          filesToShare={filesToShare}
          setFilesToShare={setFilesToShare}
        />

        {/*Unshare modal*/}
        <Modal
          title="Are you sure you want to unshare?"
          isOpen={filesToUnshare.length > 0}
          onClose={() => setFilesToUnshare([])}
        >
          <Modal.Body>
            <p className="mb-2 text-base">
              You are about to unshare {filesToUnshare.length}{' '}
              {filesToUnshare.length === 1 ? 'file' : 'files'} with the customer
              of this project.
            </p>

            <p className="mb-2 text-base">
              This will no longer allow the customer to view and download the
              files via the client portal.
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Modal.Footer.Actions>
              <PLButton
                className="mr-2"
                variant={EBtnVariant.Outline}
                disabled={isUnsharingFilesForDeal}
                onClick={() => setFilesToUnshare([])}
              >
                Cancel
              </PLButton>
              <PLButton
                onClick={handleUnshareFiles}
                disabled={isUnsharingFilesForDeal}
                isProcessing={isUnsharingFilesForDeal}
              >
                Unshare
              </PLButton>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </Modal>

        {/*Delete files modal*/}
        <Modal
          title={`Delete ${filesToDelete.length} ${
            filesToDelete.length === 1 ? 'file' : 'files'
          }`}
          isOpen={filesToDelete.length > 0}
          onClose={() => setFilesToDelete([])}
        >
          <Modal.Body>
            <p className="mb-6 text-base">
              Are you sure you would like to delete{' '}
              {filesToDelete.length === 1 ? 'this file' : 'these files'}?
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Modal.Footer.Actions>
              <PLButton
                className="mr-2"
                variant={EBtnVariant.Outline}
                disabled={isDeleting}
                onClick={() => setFilesToDelete([])}
              >
                Keep
              </PLButton>
              <PLButton
                isProcessing={isDeleting}
                disabled={isDeleting}
                colour={EBtnColour.Red}
                onClick={handeDeleteFiles}
              >
                Delete
              </PLButton>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </Modal>

        {/*Rename Modal*/}
        <RenameFileModal
          isOpen={!!fileToRename}
          fileToRename={fileToRename || undefined}
          onClose={() => setFileToRename(null)}
          onSuccess={() => {
            onDealUpdateSuccess();
            getListedFilesForDeal(getListedFilesForDealRequestData);
          }}
        />
      </div>
      {enlargeImage && (
        <Lightbox
          medium={enlargeImage.url}
          onClose={() => setEnlargeImage(null)}
          alt={enlargeImage.fileName}
        />
      )}
    </>
  );
};

export default DealFiles;
