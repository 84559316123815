// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { GetEmailTemplatesInput } from '@/gql/graphql';

const emailTemplateKeys = {
  all: ['emailTemplate'] as const,
  emailTemplates: (input: GetEmailTemplatesInput) =>
    [...emailTemplateKeys.all, 'emailTemplates', input] as const,
  emailTemplateVariables: (type: string) => {
    return [...emailTemplateKeys.all, 'emailTemplateVariables', type] as const;
  },
};

export default emailTemplateKeys;
