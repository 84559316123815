import { useQuery } from '@tanstack/react-query';

import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';
import { gqlClient } from '../../graphql-client';

const GetMe = graphql(`
  query GetMe {
    me {
      permissions
      emailSignature
      user {
        id
        fullName
        isPrimaryUser
        systemRole
        account {
          name
          phone
          email
        }
      }
      integrations {
        google {
          isActive
          email
        }
      }
    }
  }
`);

const useGetMe = () => {
  return useQuery({
    queryKey: meKeys.all,
    queryFn: async () => gqlClient.request(GetMe),
  });
};

export default useGetMe;
