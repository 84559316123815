/**
 * singularPlural
 * @param {number} length  - number being described
 * @param {string} singularString - string for singular
 * @param {string} pluralString  - string for plural
 */
export const singularPlural = (
  length: number,
  singularString: string,
  pluralString?: string,
  opt: { singularlengthAlternative?: string; lengthFormatted?: string } = {}
) => {
  const lengthString = opt.lengthFormatted
    ? opt.lengthFormatted
    : length.toString();

  if (length === 1) {
    return `${
      opt.singularlengthAlternative
        ? opt.singularlengthAlternative
        : lengthString
    } ${singularString}`;
  }

  return `${lengthString} ${
    pluralString ? pluralString : `${singularString}s`
  }`;
};

export const capitaliseFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const padLeft =
  (count: number, delim = ' ') =>
  (str: string) =>
    `${delim.repeat(count - str.length)}${str}`;

const vowels = ['a', 'e', 'i', 'o', 'u'];
export const prependIndefiniteArticle = (word: string) =>
  `${vowels.includes(word.charAt(0).toLowerCase()) ? 'an' : 'a'} ${word}`;

export const sentenceCase = (title: string) => {
  const sentences = title.split('. ');
  return sentences
    .map((sentence) => {
      return sentence
        .split(' ')
        .map((word, i) => {
          const isFirstLetterOnlyCaps = /^[A-Z][a-z]*$/.test(word);
          if (i === 0 || !isFirstLetterOnlyCaps) {
            // Don't change casing of first word or any words that are fully capitalised
            return word;
          }
          return word.toLowerCase();
        })
        .join(' ');
    })
    .join('. ');
};

/**
 * Make a human readable string from an array of strings
 * @param {string[]} arr - array of strings
 * @returns {string} - human readable string i.e. apple, orange and banana
 */
export const readableStringArray = (arr: string[]) => {
  switch (arr.length) {
    case 0:
      return '';
    case 1:
      return arr[0];
    case 2:
      return arr.join(' and ');
    default:
      return `${arr.slice(0, -1).join(', ')} and ${arr.slice(-1)[0]}`;
  }
};
