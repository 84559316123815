export const getModeElement = <T extends number[] | string[]>(
  array: T
): T[number] | null => {
  if (array.length == 0) return null;
  const modeMap: Record<number | string, number> = {};
  let maxEl = array[0],
    maxCount = 1;
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    if (modeMap[el] == null) modeMap[el] = 1;
    else modeMap[el]++;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
};

export const dedupe =
  <T, U>(selector: (x: T) => U = (x: any) => x) =>
  (arr: Array<T>): Array<T> => {
    const seen = new Set<U>();
    return arr.filter((x) => {
      const key = selector(x);
      if (seen.has(key)) {
        return false;
      }
      seen.add(key);
      return true;
    });
  };

export const mergeByKey = (
  key: string,
  options: { reverse: boolean } = { reverse: false }
) => {
  if (options.reverse) {
    // Reverse merge
    // Incoming items are prepended to existing items
    return (existing: any[] = [], incoming: any[]) => {
      const existingMap = new Map(existing.map((i: any) => [i[key], i]));
      const merged = new Map(
        incoming.map((i: any) => [i[key], { ...existingMap.get(i[key]), ...i }])
      );

      existingMap.forEach((i: any) => {
        if (!merged.has(i[key])) {
          merged.set(i[key], {
            ...i,
          });
        }
      });

      return Array.from(merged.values());
    };
  } else {
    return (existing: any[] = [], incoming: any[]) => {
      const merged = new Map(existing.map((i: any) => [i[key], i]));

      // Add or overwrite with incoming tasks
      incoming.forEach((i: any) => {
        merged.set(i[key], {
          ...merged.get(i[key]),
          ...i,
        });
      });

      return Array.from(merged.values());
    };
  }
};

export const splitIntoChunks = <T>(arr: Array<T>, chunkSize: number) => {
  if (chunkSize < 1) {
    throw new RangeError('chunkSize must be greater than 0');
  }
  const chunks: Array<Array<T>> = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};
