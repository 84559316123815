import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { EmailTemplateType } from '@/gql/graphql';
import { apiFetch } from '@/utils/apiFetch';

type EmailTemplatePreviewResponse = {
  valid: boolean;
  template: string;
};

type EmailTemplatePreviewBody = {
  type: EmailTemplateType;
  template: string;
};

const usePostEmailTemplatePreview = (
  options?: UseMutationOptions<
    EmailTemplatePreviewResponse,
    EmailTemplatePreviewResponse,
    EmailTemplatePreviewBody
  >
) => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await apiFetch(
        `/provider/rest/email-templates/preview`,
        {
          method: 'POST',
          body: JSON.stringify(body),
        }
      );

      const json = (await response.json()) as EmailTemplatePreviewResponse;

      if (!json.valid) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw json;
      }

      return json;
    },
    ...options,
  });
};

export default usePostEmailTemplatePreview;
