import { useSelector } from '@/api/state';
import Button from '@payaca/components/plButton/Button';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Table from '@payaca/components/plTable/Table';
import QuickActionsTableCell from '@payaca/components/quickActionsTableCell/QuickActionsTableCell';
import { getTaxRates } from '@payaca/store/tax-rates/actions';
import { TaxRate } from '@payaca/types/taxRateTypes';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import CompanySettingsTabBase from './CompanySettingsTabBase';
import CreateEditTaxRateModal from './CreateEditTaxRateModal';
import DeleteTaxRateConfirmationModal from './DeleteTaxRateConfirmationModal';

type Props = {
  readOnly: boolean;
};
const CompanySettingsTaxRates: FC<Props> = ({
  readOnly,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { busyTaxRateIds, store: taxRates } = useSelector(
    (state) => state.taxRates
  );

  const [taxRate, setTaxRate] = useState<TaxRate | null>(null);
  const [isEditingTaxRate, setIsEditingTaxRate] = useState(false);
  const [isDeletingTaxRate, setIsDeletingTaxRate] = useState(false);

  useEffect(() => {
    dispatch(getTaxRates.request());
  }, []);

  const taxRatesTable = useMemo(() => {
    const sortedTaxRates = Object.values(taxRates).sort((a, b) => {
      const a1 = a.isSystemManaged ? 0 : 100000;
      // const a2 = a.isIncluded ? 0 : 10000;
      const a3 = a.isReverseCharge ? 1000 : 0;
      const a4 = a.percentage;

      const b1 = b.isSystemManaged ? 0 : 100000;
      // const b2 = b.isIncluded ? 0 : 10000;
      const b3 = b.isReverseCharge ? 1000 : 0;
      const b4 = b.percentage;

      // return (a1 + a2 + a3 + a4) - (b1 + b2 + b3 + b4);
      return a.id - b.id;
    });

    return (
      <ManageableItemsList>
        <ManageableItemsList.HeaderBar
          heading="Tax rates"
          buttons={
            <Button
              onClick={() => {
                setTaxRate(null);
                setIsEditingTaxRate(true);
              }}
              disabled={readOnly}
              size={EBtnSize.Small}
            >
              Create tax rate
            </Button>
          }
        />
        <ManageableItemsList.Table items={sortedTaxRates} uniqueKey="id">
          <Table.Column field="name" header="Name" />
          <Table.Column
            field="percentage"
            header="Percentage"
            render={(field, row) => (row.isIncluded ? `${field}%` : '-')}
          />
          <Table.Column
            field="isReverseCharge"
            header="Reverse charge"
            render={(field, row) =>
              row.isIncluded ? <Checkbox disabled checked={field} /> : '-'
            }
          />
          {!readOnly && (
            <Table.Column
              field="isSystemManaged"
              header=""
              hiddenHeader
              render={(field, row) =>
                !field ? (
                  <QuickActionsTableCell
                    recordId={row.id}
                    quickActions={
                      row.isBusy
                        ? []
                        : [
                            {
                              actionName: 'Edit',
                              actionBehaviour: (taxRateId: number) => {
                                setTaxRate(taxRates[taxRateId]);
                                setIsEditingTaxRate(true);
                              },
                            },
                            {
                              actionName: 'Delete',
                              actionBehaviour: (taxRateId: number) => {
                                setTaxRate(taxRates[taxRateId]);
                                setIsDeletingTaxRate(true);
                              },
                            },
                          ]
                    }
                  />
                ) : (
                  <td />
                )
              }
            />
          )}
        </ManageableItemsList.Table>
      </ManageableItemsList>
    );
  }, [taxRates, busyTaxRateIds]);

  return (
    <CompanySettingsTabBase>
      {taxRatesTable}
      <CreateEditTaxRateModal
        isOpen={isEditingTaxRate}
        onClose={() => {
          setIsEditingTaxRate(false);
        }}
        taxRate={taxRate}
      />
      <DeleteTaxRateConfirmationModal
        isOpen={isDeletingTaxRate}
        onClose={() => {
          setIsDeletingTaxRate(false);
        }}
        taxRateId={taxRate?.id}
      />
    </CompanySettingsTabBase>
  );
};

export default CompanySettingsTaxRates;
