import { FC, PropsWithChildren, useMemo } from 'react';

import Modal from '@payaca/components/modal/Modal';

import { useSelector } from '@/api/state';
import {
  getDeal,
  getJobPayment,
  getJobsByDealId,
} from '@/utils/stateAccessors';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import ConfirmPaymentControl from '../confirmPaymentControl/ConfirmPaymentControl';

type Props = {
  jobPaymentId: number;
  isOpen: boolean;
  confirmPaymentCallback?: () => void;
  onClose?: () => void;
};
const ConfirmPaymentModal: FC<Props> = ({
  jobPaymentId,
  isOpen,
  confirmPaymentCallback,
  onClose,
}: PropsWithChildren<Props>): JSX.Element => {
  const jobPayment = useSelector((state) => {
    return getJobPayment(state, jobPaymentId);
  });

  const deal = useSelector((state) => {
    if (!jobPayment) return;
    return getDeal(state, jobPayment.dealId);
  });

  const jobs = useSelector((state) => {
    if (!jobPayment) return;
    return getJobsByDealId(state, jobPayment.dealId);
  });

  const customer = useSelector((state) => {
    return state.customer.currentCustomer;
  });
  const isGettingCustomer = useSelector(
    (state: any) => state.customer.isGettingCustomer
  );

  const job = useMemo(() => {
    if (!jobPayment || !jobs) return;
    return jobs.find((job) => !job.inactivatedAt && !job.archivedAt);
  }, [jobs, jobPayment]);

  const customerContact = useMemo(() => {
    if (!job || !customer) return;
    return getJobContactFromCustomer(customer, job.contactId);
  }, [job, customer]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirm a payment">
      {isGettingCustomer && <MiniLoader />}

      {true && jobPayment && (
        <ConfirmPaymentControl
          jobPayment={jobPayment}
          customerName={customerContact?.name || ''}
          dealReference={deal?.customReference || String(deal?.reference)}
          confirmPaymentCallback={confirmPaymentCallback}
        />
      )}
    </Modal>
  );
};

export default ConfirmPaymentModal;
