import { AutomationDataAccessType } from './automationDataAccessTypes';
import { AutomationEntityTypes } from './automationTypes';

const accountAutomationVariableTypes = [
  AutomationDataAccessType.ACCOUNT_COMPANYNAME,
  AutomationDataAccessType.ACCOUNT_VATNUMBER,
  AutomationDataAccessType.ACCOUNT_ACCOUNTNAME,
  AutomationDataAccessType.ACCOUNT_ACCOUNTNUMBER,
  AutomationDataAccessType.ACCOUNT_ADDRESS,
  AutomationDataAccessType.ACCOUNT_BUSINESSNOTES,
  AutomationDataAccessType.ACCOUNT_CITY,
  AutomationDataAccessType.ACCOUNT_COMPANYNAME,
  AutomationDataAccessType.ACCOUNT_CONTACTNUMBER,
  AutomationDataAccessType.ACCOUNT_EMAILADDRESS,
  AutomationDataAccessType.ACCOUNT_PAYMENTTERMS,
  AutomationDataAccessType.ACCOUNT_POSTCODE,
  AutomationDataAccessType.ACCOUNT_SORTCODE,
];

const customerAutomationVariableTypes = [
  AutomationDataAccessType.CUSTOMER_NAME,
  AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
  AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_NAME,
  AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
];

const dealAutomationVariableTypes = [
  AutomationDataAccessType.DEAL_CREATEDAT,
  AutomationDataAccessType.DEAL_DESCRIPTION,
  AutomationDataAccessType.DEAL_REFERENCE,
  AutomationDataAccessType.DEAL_SITEADDRESSES_ARRAYSTRING,
  AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE,
];

const scheduledEventAutomationVariableTypes = [
  AutomationDataAccessType.SCHEDULEDEVENT_NAME,
  AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
  AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT_FORMATTEDDATE,
  AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT_FORMATTEDTIME,
  AutomationDataAccessType.SCHEDULEDEVENT_DESCRIPTION,
  AutomationDataAccessType.SCHEDULEDEVENT_ENDAT,
  AutomationDataAccessType.SCHEDULEDEVENT_ENDAT_FORMATTEDDATE,
  AutomationDataAccessType.SCHEDULEDEVENT_ENDAT_FORMATTEDTIME,
  AutomationDataAccessType.SCHEDULEDEVENT_LOCATION_ADDRESS_STRING,
  AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_NAME_ARRAYSTRING,
  AutomationDataAccessType.SCHEDULEDEVENT_TASKSTATUS,
];

const invoiceAutomationVariableTypes = [
  AutomationDataAccessType.INVOICE_AMOUNTDUE,
  AutomationDataAccessType.INVOICE_CREATEDAT,
  AutomationDataAccessType.INVOICE_CREATEDAT_FORMATTEDDATE,
  AutomationDataAccessType.INVOICE_DUEAT,
  AutomationDataAccessType.INVOICE_DUEAT_FORMATTEDDATE,
  AutomationDataAccessType.INVOICE_READABLE_STATUS,
  AutomationDataAccessType.INVOICE_REFERENCE,
  AutomationDataAccessType.INVOICE_SENTAT,
  AutomationDataAccessType.INVOICE_VIEWLINK,
  AutomationDataAccessType.INVOICE_SENTAT_FORMATTEDDATE,
  AutomationDataAccessType.INVOICE_VIEWBUTTON,
  AutomationDataAccessType.INVOICE_VIEWEDAT,
  AutomationDataAccessType.INVOICE_VIEWEDAT_FORMATTEDDATE,
];

const proposalAutomationVariableTypes = [
  AutomationDataAccessType.PROPOSAL_ACCEPTEDAT,
  AutomationDataAccessType.PROPOSAL_ACCEPTEDAT_FORMATTEDDATE,
  AutomationDataAccessType.PROPOSAL_ACCEPTEDAT_FORMATTEDTIME,
  AutomationDataAccessType.PROPOSAL_CREATEDAT,
  AutomationDataAccessType.PROPOSAL_CREATEDAT_FORMATTEDDATE,
  AutomationDataAccessType.PROPOSAL_READABLE_STATUS,
  AutomationDataAccessType.PROPOSAL_REFERENCE,
  AutomationDataAccessType.PROPOSAL_SENTAT,
  AutomationDataAccessType.PROPOSAL_SENTAT_FORMATTEDDATE,
  AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
  AutomationDataAccessType.PROPOSAL_VIEWLINK,
  AutomationDataAccessType.PROPOSAL_VALIDUNTIL_FORMATTEDDATE,
  AutomationDataAccessType.PROPOSAL_VIEWBUTTON,
  AutomationDataAccessType.PROPOSAL_VIEWEDAT,
  AutomationDataAccessType.PROPOSAL_VIEWEDAT_FORMATTEDDATE,
];

const userAutomationVariableTypes = [
  AutomationDataAccessType.USER_CONTACTNUMBER,
  AutomationDataAccessType.USER_EMAILADDRESS,
  AutomationDataAccessType.USER_FIRSTNAME,
  AutomationDataAccessType.USER_GASSAFEIDCARDNUMBER,
  AutomationDataAccessType.USER_LASTNAME,
  AutomationDataAccessType.USER_SYSTEMROLE,
];

export const automationEntityTypeActionVariablesMap: {
  [key in AutomationEntityTypes]?: AutomationDataAccessType[];
} = {
  [AutomationEntityTypes.DEAL]: [
    ...accountAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...customerAutomationVariableTypes,
  ],
  [AutomationEntityTypes.ESTIMATE]: [
    ...proposalAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...userAutomationVariableTypes,
  ],
  [AutomationEntityTypes.QUOTE]: [
    ...proposalAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...userAutomationVariableTypes,
  ],
  [AutomationEntityTypes.PROPOSAL]: [
    ...proposalAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...userAutomationVariableTypes,
  ],
  [AutomationEntityTypes.INVOICE]: [
    ...invoiceAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...userAutomationVariableTypes,
  ],
  [AutomationEntityTypes.INVOICE_V2]: [
    ...invoiceAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
  ],
  [AutomationEntityTypes.EVENT]: [
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...scheduledEventAutomationVariableTypes,
  ],
};
