import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

type Props = {
  className?: string;
};
const CompanySettingsTabBase: FC<PropsWithChildren<Props>> = (props) => {
  const { children, className } = props;
  return (
    <div className={clsx('flex flex-col gap-y-4 w-full max-w-[1200px] m-auto', className)}>{children}</div>
  );
};

export default CompanySettingsTabBase;
