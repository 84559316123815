import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateFieldServiceSupplierIdsInput,
  UpdateFieldServiceSupplierIdsMutation,
} from '@/gql/graphql';

const UPDATE_FIELD_SERVICE_SUPPLIER_IDS = graphql(`
  mutation UpdateFieldServiceSupplierIds(
    $input: UpdateFieldServiceSupplierIdsInput!
  ) {
    updateFieldServiceSupplierIds(input: $input) {
      __typename
      ... on UpdateFieldServiceSupplierIdsResponseSuccess {
        allowedSupplierIds
      }
    }
  }
`);

const useUpdateFieldServiceSupplierIds = (
  options?: UseMutationOptions<
    UpdateFieldServiceSupplierIdsMutation,
    unknown,
    UpdateFieldServiceSupplierIdsInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_FIELD_SERVICE_SUPPLIER_IDS, { input });
    },
    ...options,
  });
};

export default useUpdateFieldServiceSupplierIds;
