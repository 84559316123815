import { TFormState } from '@/ui/components/createPurchaseOrderSidebar/CreatePurchaseOrderSidebar';
import { PurchaseOrderDeliveryAddressControl } from '@/ui/components/purchaseOrderDeliveryAddressControl/PurchaseOrderDeliveryAddressControl';
import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import { DateInput } from '@payaca/components/plDateInput/DateInput';
import Field from '@payaca/components/plField/Field';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import { clstx } from '@payaca/components/utils';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface IProps {
  formStage: 'MATERIAL_LIST_MATERIALS' | 'PURCHASE_ORDER_DETAILS';
  projectId: number;
  hideList?: boolean;
  isSaving: boolean;
}

const PurchaseOrderDetails: FC<IProps> = (props) => {
  const { formStage, projectId, hideList = false, isSaving } = props;

  const formMethods = useFormContext<TFormState>();

  const selectedSupplierId = formMethods.watch('supplierId');

  return (
    <div className="bg-gray-50 p-3.5">
      <div
        className={clstx('flex flex-col gap-4 h-full', hideList && 'hidden')}
      >
        <h4>Order details</h4>

        <Field
          validationState={
            formMethods.formState.errors.dueAt
              ? {
                  isValid: false,
                  validationMessages: ['Due by required'],
                }
              : undefined
          }
        >
          <Field.Label>Due by</Field.Label>

          <Conditional
            condition={formStage === 'PURCHASE_ORDER_DETAILS' || !hideList}
          >
            <Controller
              name="dueAt"
              control={formMethods.control}
              render={({ field: { onChange, value } }) => {
                return <DateInput value={value} onChange={onChange} />;
              }}
            />
          </Conditional>
        </Field>

        <Field>
          <Field.Label>Deliver to</Field.Label>

          <Controller
            name="deliveryInfo"
            control={formMethods.control}
            render={({ field: { onChange, value } }) => {
              return (
                <PurchaseOrderDeliveryAddressControl
                  supplierId={selectedSupplierId}
                  projectId={projectId}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </Field>

        <Field name="additionalNotes">
          <Field.Label>Additional notes</Field.Label>

          <Controller
            name="additionalNotes"
            control={formMethods.control}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <Textarea value={value} onChange={onChange} onBlur={onBlur} />
              );
            }}
          />
        </Field>

        <Field name="showMaterialPrices">
          <Checkbox
            label="Show Material prices on Purchase Order"
            {...formMethods.register('showMaterialPrices')}
          />
        </Field>

        <Button
          className="mt-auto"
          type="submit"
          isProcessing={isSaving}
          disabled={isSaving}
        >
          Create order
        </Button>
      </div>
    </div>
  );
};

export default PurchaseOrderDetails;
