import useGetEmailTemplateAvailableVariables from '@/api/rest/emailTemplates/useGetEmailTemplateAvailableVariables';
import { EmailTemplateType } from '@/gql/graphql';
import { emailTemplateTypeSelectOptions } from '@/ui/components/companySettings/components/CreateEmailTemplateModal';
import Card from '@payaca/components/plCard/Card';
import Select from '@payaca/components/plSelect/Select';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { FC, useState } from 'react';

const AvailableVariablesCard: FC = () => {
  const { pushToast } = useToastContext();

  const [selectedEmailTemplateType, setSelectedEmailTemplateType] =
    useState<EmailTemplateType>();

  const { data } = useGetEmailTemplateAvailableVariables(
    selectedEmailTemplateType
  );

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    pushToast({
      variant: 'white',
      icon: 'success',
      message: 'Copied to clipboard',
    });
  };

  return (
    <Card>
      <Card.Body className="space-y-4">
        <h2>Available variables</h2>

        <Select
          multiple={false}
          options={emailTemplateTypeSelectOptions}
          value={selectedEmailTemplateType}
          onChange={(newValue) => {
            console.log(newValue);
            setSelectedEmailTemplateType(newValue as EmailTemplateType);
          }}
        />

        <div className="grid grid-cols-2 bg-gray-200 gap-[1px]">
          {data?.map((variable) => {
            return (
              <button
                type="button"
                key={variable.key}
                className="p-4 hover:bg-gray-50 cursor-pointer bg-white text-left"
                onClick={() => handleCopy(`{{${variable.key}}}`)}
              >
                <div className="flex justify-between">
                  <h4>{variable.label}</h4>
                  <p>
                    {'{{'}
                    {variable.key}
                    {'}}'}
                  </p>
                </div>
                <p className="supporting-body text-gray-500">
                  {variable.example}
                </p>
              </button>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AvailableVariablesCard;
