import { useQuery } from '@tanstack/react-query';

import emailTemplateKeys from '@/api/queries/emailTemplates/keyFactor';
import { EmailTemplateType } from '@/gql/graphql';
import { apiFetch } from '@/utils/apiFetch';

type EmailTemplateAvailableVariablesResponse = {
  key: string;
  label: string;
  example: string;
}[];

const useGetEmailTemplateAvailableVariables = (type?: EmailTemplateType) => {
  return useQuery({
    enabled: !!type,
    queryKey: emailTemplateKeys.emailTemplateVariables(type!),
    queryFn: async () => {
      const response = await apiFetch(
        `/provider/rest/email-templates/${type}/variables`
      );

      return response.json() as Promise<EmailTemplateAvailableVariablesResponse>;
    },
  });
};

export default useGetEmailTemplateAvailableVariables;
