import { Transition } from '@headlessui/react';
import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import CardButton from '@payaca/components/plCard/CardButton';
import IncrementableNumberInput from '@payaca/components/plInput/IncrementableNumberInput';
import { CurrencyValue as TCurrencyValue } from '@payaca/types/currencyTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { FC } from 'react';

type OwnProps = {
  name: string;
  price?: TCurrencyValue;
  thumbnailUrl?: string;
  childrenClassName?: string;
  children?: React.ReactNode;
};

export type MaterialCardWithQuantityProps = OwnProps & {
  quantity: number;
  onChangeQuantity: (quantity: number) => void;
  maxQuantity?: number;
};

export type MaterialCardProps =
  | OwnProps
  | (OwnProps & {
      actionOnClick: () => void;
      actionDisabled?: boolean;
    })
  | MaterialCardWithQuantityProps;

export type MaterialCardPropsWithTransition = MaterialCardProps & {
  afterLeave: () => void;
  show: boolean;
};

export const MaterialCardWithTransition: FC<MaterialCardPropsWithTransition> = (
  props
) => {
  const { afterLeave, show, ...rest } = props;

  return (
    <Transition
      as="div"
      appear
      unmount
      show={show}
      enter="transition duration-300"
      enterFrom="opacity-0 -translate-x-full"
      enterTo="opacity-100 translate-x-0"
      leave="transition duration-300"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 -translate-x-full"
      afterLeave={afterLeave}
    >
      <MaterialCard {...rest} />
    </Transition>
  );
};

const MaterialCard: FC<MaterialCardProps> = (props) => {
  const { name, price, thumbnailUrl, childrenClassName, children } = props;

  const wrapperProps = {
    sizeVariant: CardSizeVariant.SM,
    onClick: 'actionOnClick' in props ? props.actionOnClick : undefined,
    disabled: 'actionDisabled' in props ? props.actionDisabled : undefined,
  };

  return (
    <ConditionalWrapper
      condition={'actionOnClick' in props}
      Wrapper={CardButton}
      FallbackWrapper={Card}
      wrapperProps={wrapperProps}
      fallbackWrapperProps={wrapperProps}
    >
      <Card.Body>
        <div className="flex gap-3.5">
          <div className="w-14">
            {thumbnailUrl ? (
              <img
                className="overflow-hidden rounded"
                src={thumbnailUrl}
                alt={name}
              />
            ) : (
              <UntitledIcon
                name="image-01.3"
                vectorEffect="non-scaling-stroke"
                className="h-10 w-10 text-gray-700"
              />
            )}
          </div>

          <div className="flex flex-auto flex-col gap-3.5">
            <div className="flex justify-between gap-3.5">
              <h4>{name}</h4>

              {price ? <CurrencyValue {...price} /> : '-'}
            </div>

            <div className="flex flex-wrap items-center justify-between">
              <div className={childrenClassName}>{children}</div>

              {'quantity' in props && (
                <IncrementableNumberInput
                  wrapperClassName="max-w-32"
                  value={props.quantity}
                  onChange={props.onChangeQuantity}
                  min={0}
                  max={props.maxQuantity}
                />
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </ConditionalWrapper>
  );
};

export default MaterialCard;
