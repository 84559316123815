import { FC } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import Alert, { EAlertColour } from '../plAlert/Alert';
import UntitledLoading from '../plUntitledLoading/UntitledLoading';
import { clstx } from '../utils';
import FileDropIcon from './FileDropIcon.svg?react';

export type Props = {
  className?: string;
  isLoading?: boolean;
  hasError?: boolean;
  dropzoneOptions?: DropzoneOptions;
};

const FileDropZone: FC<Props> = (props) => {
  const {
    className,
    isLoading = false,
    hasError = false,
    dropzoneOptions,
  } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: dropzoneOptions?.disabled || isLoading,
    ...dropzoneOptions,
  });

  return (
    <div
      className={clstx(
        'relative m-auto cursor-pointer rounded-xl border border-dashed p-10',
        isDragActive
          ? 'border-blue-600 bg-blue-50'
          : 'border-gray-300 bg-white',
        className
      )}
      {...getRootProps()}
    >
      <div
        className={
          'prose flex flex-col items-center text-center' +
          (isLoading ? ' invisible' : '')
        }
      >
        <input {...getInputProps()} />

        {hasError && (
          <Alert className="mb-4" colour={EAlertColour.SOFT_RED}>
            An error occurred - please try again later
          </Alert>
        )}
        <FileDropIcon className="mb-4" />
        <h3 className="mb-1">
          Drop your file{dropzoneOptions?.maxFiles === 1 ? '' : 's'} here or{' '}
          <b className="font-semibold text-cyan-800 underline underline-offset-2">
            browse
          </b>
        </h3>
        <p>Maximum size: 50MB</p>
      </div>

      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <UntitledLoading className="h-6 w-6" />
        </div>
      ) : null}
    </div>
  );
};

export default FileDropZone;
