import { useProjectCustomFields } from '@/hooks/useProjectCustomFields';
import { automationDataAccessTypeReadableNameMap } from '@payaca/helpers/automationHelper';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import { AutomationEntityTypes } from '@payaca/types/automationTypes';
import { automationEntityTypeActionVariablesMap } from '@payaca/types/automationVariableTypes';
import { useMemo } from 'react';

export const useActionVariablesForAutomationEntityType = (
  automationEntityType: AutomationEntityTypes
) => {
  const projectCustomFields = useProjectCustomFields();

  const customFieldVariables = useMemo(() => {
    return projectCustomFields.map(
      (field) =>
        `${AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE}:${field.identifier}`
    );
  }, [projectCustomFields]);

  // TODO: Apply some additional filtering here - not all variables should be available on default automations
  const variables = useMemo(() => {
    const variables =
      automationEntityTypeActionVariablesMap[automationEntityType] || [];

    const customFieldsVariableIndex = variables.indexOf(
      AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE
    );
    if (customFieldsVariableIndex !== -1) {
      // @ts-expect-error
      variables.splice(customFieldsVariableIndex, 1, ...customFieldVariables);
    }

    return variables;
  }, [automationEntityType, customFieldVariables]);

  const variableReadableNameMap = useMemo(() => {
    const map: Record<string, string> = automationDataAccessTypeReadableNameMap;

    projectCustomFields.forEach((field) => {
      map[
        `${AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE}:${field.identifier}`
      ] = `${
        automationDataAccessTypeReadableNameMap[
          AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE
        ]
      }: ${field.identifier}`;
    });

    return map;
  }, [projectCustomFields]);

  return {
    variables,
    variableReadableNameMap,
  };
};
