import usePostEmailTemplatePreview from '@/api/rest/emailTemplates/usePostEmailTemplatePreview';
import { EmailTemplateType } from '@/gql/graphql';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnShapeVariant,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card from '@payaca/components/plCard/Card';
import Field, { FieldValidationState } from '@payaca/components/plField/Field';
import FileDropZone from '@payaca/components/plFileDropZone/FileDropZone';
import ShadowDomRenderer from '@payaca/components/shadowDomRenderer/ShadowDomRenderer';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, Fragment, useEffect, useState } from 'react';

export interface IProps {
  validationState?: FieldValidationState;
  type: EmailTemplateType;
  value?: string;
  onChange?: (value?: string) => void;
}

const EmailTemplateUploadField: FC<IProps> = (props) => {
  const { validationState, type, value, onChange } = props;

  const [isReadingFileLocally, setIsReadingFileLocally] = useState(false);
  const [hasError, setHasError] = useState<string | null>(null);

  const { mutateAsync: generateEmailTemplatePreview, isLoading } =
    usePostEmailTemplatePreview();

  useEffect(() => {
    setHasError(null);
  }, [type]);

  const onFileUpload = (file: File) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        if (!reader.result) {
          return;
        }

        generateEmailTemplatePreview({
          type,
          template: reader.result as string,
        })
          .then((res) => {
            onChange?.(res.template);
          })
          .catch((error) => {
            setHasError(error.message);
          });
      },
      false
    );

    reader.addEventListener('loadstart', () => {
      setIsReadingFileLocally(true);
    });

    reader.addEventListener('loadend', () => {
      setIsReadingFileLocally(false);
    });

    if (file) {
      setHasError(null);
      reader.readAsText(file);
    }
  };

  return (
    <>
      <Field validationState={validationState}>
        <Field.Label>Template</Field.Label>

        {value ? (
          <>
            <Alert>
              The Template is valid. Please note that the data populated here is{' '}
              <b>not real and is only for preview purposes</b>.
            </Alert>

            <Card className="overflow-hidden relative mt-4">
              <div className="absolute right-2 top-2">
                <Button
                  onClick={() => onChange?.(undefined)}
                  variant={EBtnVariant.White}
                  shape={EBtnShapeVariant.PILL}
                  size={EBtnSize.Small}
                >
                  <UntitledIcon className="h-4 w-3" name="x-close" />
                </Button>
              </div>

              <ShadowDomRenderer html={value} />
            </Card>
          </>
        ) : (
          <FileDropZone
            isLoading={isReadingFileLocally || isLoading}
            dropzoneOptions={{
              maxFiles: 1,
              onDrop: (files) => {
                onFileUpload(files[0]);
              },
            }}
          />
        )}

        {hasError && (
          <Alert colour={EAlertColour.SOFT_RED} className="mt-4">
            {hasError.split('\n').map((text, index) => (
              <Fragment key={index}>
                {text}
                <br />
              </Fragment>
            ))}
          </Alert>
        )}
      </Field>
    </>
  );
};

export default EmailTemplateUploadField;
