import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  CreateEmailTemplateInput,
  CreateEmailTemplateMutation,
} from '@/gql/graphql';

const CREATE_EMAIL_TEMPLATE_MUTATION = graphql(`
  mutation CreateEmailTemplate($input: CreateEmailTemplateInput!) {
    createEmailTemplate(input: $input) {
      id
      name
      type
    }
  }
`);

const useCreateEmailTemplate = (
  options?: UseMutationOptions<
    CreateEmailTemplateMutation,
    unknown,
    CreateEmailTemplateInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CREATE_EMAIL_TEMPLATE_MUTATION, { input });
    },
    ...options,
  });
};

export default useCreateEmailTemplate;
