import { FC } from 'react';
import ModalCore, { IModalCoreProps } from '../plModal/ModalCore';
import FileDropZone, { Props as FileDropProps } from './FileDropZone';

const FileDropZoneModal: FC<IModalCoreProps & FileDropProps> = (props) => {
  const {
    isLoading = false,
    hasError = false,
    className,
    dropzoneOptions,
    ...rest
  } = props;

  return (
    <ModalCore
      className={'m-auto' + (className ? ' ' + className : '')}
      {...rest}
    >
      <FileDropZone
        className="border-0"
        isLoading={isLoading}
        hasError={hasError}
        dropzoneOptions={dropzoneOptions}
      />
    </ModalCore>
  );
};

export default FileDropZoneModal;
