import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';
import { useQuery } from '@tanstack/react-query';

const GetMyAccountFieldServiceSettingsQuery = graphql(`
  query MyAccountFieldServiceSettings {
    me {
      user {
        account {
          fieldServiceSettings {
            allowedSupplierIds
          }
        }
      }
    }
  }
`);

const useGetMyAccountFieldServiceSettings = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountFieldServiceSettings(),
    queryFn: () => {
      return gqlClient.request(GetMyAccountFieldServiceSettingsQuery);
    },
  });

  return { data: data?.me.user.account.fieldServiceSettings, ...rest };
};

export default useGetMyAccountFieldServiceSettings;
