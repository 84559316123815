import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useState } from 'react';

import projectKeys from '@/api/queries/project/keyFactory';
import CreatePurchaseOrderSidebar from '@/ui/components/createPurchaseOrderSidebar/CreatePurchaseOrderSidebar';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { useMaterialsList } from '@payaca/store/hooks/appState';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { Deal } from '@payaca/types/dealTypes';
import { MaterialsList } from '@payaca/types/materialsListTypes';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const CreatePurchaseOrderControl: FC<{
  projectId: Deal['id'];
  materialsListId: MaterialsList['id'];
}> = (props) => {
  const { projectId, materialsListId } = props;
  const { url } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [showCreatePurchaseOrderModal, setShowCreatePurchaseOrderModal] =
    useState(false);

  const handleGetMaterialsListWithRelatedEntities = useCallback(
    (materialsListId: number) => {
      dispatch(
        materialsListActions.requestGetMaterialsListWithRelatedEntities(
          materialsListId
        )
      );
    },
    []
  );

  useEffect(() => {
    handleGetMaterialsListWithRelatedEntities(materialsListId);
  }, []);

  const materialsList = useMaterialsList(materialsListId);

  const canCreatePurchaseOrder =
    materialsList &&
    !materialsList.isFullyPurchased &&
    !materialsList.isFullyPurchaseIntended;

  if (!canCreatePurchaseOrder) return null;

  return (
    <>
      <PermissionGuard
        renderIfHasPermissions={[
          MaterialsListPermissions.PERSIST_PURCHASE_ORDER,
        ]}
      >
        <Button
          onClick={() => {
            history.push(`${url}/purchase-orders`);
            setShowCreatePurchaseOrderModal(true);
          }}
          size={EBtnSize.Small}
          variant={EBtnVariant.White}
        >
          Create PO
        </Button>
      </PermissionGuard>

      <CreatePurchaseOrderSidebar
        projectId={projectId}
        materialsListId={materialsListId}
        isOpen={showCreatePurchaseOrderModal}
        onClose={() => setShowCreatePurchaseOrderModal(false)}
        onSuccess={(purchaseOrderId) => {
          setShowCreatePurchaseOrderModal(false);
          handleGetMaterialsListWithRelatedEntities(materialsListId);

          void queryClient.invalidateQueries(
            projectKeys.materialsListMaterials(projectId)
          );
          void queryClient.invalidateQueries(
            projectKeys.materialsListSummaryItems(projectId)
          );
          void queryClient.invalidateQueries(
            projectKeys.materialsListAuditLogs(projectId)
          );
          void queryClient.invalidateQueries(
            projectKeys.purchaseOrders(projectId)
          );

          history.push(
            `/purchaseOrders/${purchaseOrderId}?projectId=${projectId}`
          );
        }}
      />
    </>
  );
};
