import useCreateEmailTemplate from '@/api/mutations/emailTemplates/useCreateEmailTemplate';
import emailTemplateKeys from '@/api/queries/emailTemplates/keyFactor';
import { EmailTemplateType } from '@/gql/graphql';
import EmailTemplateUploadField from '@/ui/components/companySettings/components/EmailTemplateUploadField';
import { zodHumanFriendlyFormErrorMap } from '@/utils/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import RawInput from '@payaca/components/plInput/RawInput';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import Select from '@payaca/components/plSelect/Select';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

export const emailTemplateTypeSelectOptions = [
  {
    label: 'Automation template (Estimate)',
    value: 'AUTOMATION_TEMPLATE_ESTIMATE',
  },
  {
    label: 'Automation template (Quote)',
    value: 'AUTOMATION_TEMPLATE_QUOTE',
  },
  {
    label: 'Automation template (Invoice)',
    value: 'AUTOMATION_TEMPLATE_INVOICE',
  },
  {
    label: 'Automation template (Event)',
    value: 'AUTOMATION_TEMPLATE_EVENT',
  },
  {
    label: 'Automation template (Project)',
    value: 'AUTOMATION_TEMPLATE_PROJECT',
  },
  {
    label: 'Automation template (Proposal)',
    value: 'AUTOMATION_TEMPLATE_PROPOSAL',
  },
];

type TFormState = {
  name: string;
  type: EmailTemplateType;
  template?: string;
};

const formSchema = z.object({
  name: z.string().min(1),
  type: z.string(),
  template: z.string().min(1),
});

const CreateEditEmailTemplateModalContent: FC<{
  isProcessing?: boolean;
  onSubmit: (state: TFormState) => void;
}> = (props) => {
  const { onSubmit, isProcessing = false } = props;

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(formSchema, {
      errorMap: zodHumanFriendlyFormErrorMap,
    }),
  });

  const templateType = formMethods.watch('type');

  return (
    <>
      <form onSubmit={formMethods.handleSubmit(onSubmit, console.error)}>
        <Modal.Body className="space-y-4">
          <Field
            validationState={
              formMethods.formState.errors.name
                ? {
                    isValid: false,
                    validationMessages: ['Name is required'],
                  }
                : undefined
            }
          >
            <Field.Label>Name</Field.Label>

            <RawInput {...formMethods.register('name')} />
          </Field>

          <Field
            validationState={
              formMethods.formState.errors.type
                ? {
                    isValid: false,
                    validationMessages: ['Type is required'],
                  }
                : undefined
            }
          >
            <Field.Label>Type</Field.Label>

            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    onChange={(value) => {
                      onChange(value);

                      formMethods.setValue('template', undefined);
                    }}
                    options={emailTemplateTypeSelectOptions}
                  />
                );
              }}
              name={'type'}
              control={formMethods.control}
            />
          </Field>

          {!!templateType && (
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <EmailTemplateUploadField
                    validationState={
                      formMethods.formState.errors.template
                        ? {
                            isValid: false,
                            validationMessages: ['Template is required'],
                          }
                        : undefined
                    }
                    type={templateType}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              name={'template'}
              control={formMethods.control}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              disabled={isProcessing}
              isProcessing={isProcessing}
              type="submit"
            >
              Submit
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>
    </>
  );
};

const CreateEmailTemplateModal: FC<IModalProps> = (props) => {
  const { onClose, ...rest } = props;

  const queryClient = useQueryClient();
  const { mutateAsync: createEmailTemplate, isLoading } =
    useCreateEmailTemplate();

  const handleSubmit = async (state: TFormState) => {
    if (!state.template) {
      return;
    }

    await createEmailTemplate({
      name: state.name,
      type: state.type,
      template: state.template,
    });

    await queryClient.invalidateQueries({
      queryKey: emailTemplateKeys.emailTemplates({}),
    });

    onClose?.();
  };

  return (
    <Modal size="lg" draggable={false} onClose={onClose} {...rest}>
      <CreateEditEmailTemplateModalContent
        isProcessing={isLoading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default CreateEmailTemplateModal;
