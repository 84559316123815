import UntitledIcon from '@payaca/untitled-icons';
import { FC, useEffect, useState } from 'react';
import { EBtnVariant } from '../plButton/useButtonClassName';
import InputGroup, { InputGroupInput } from '../plInputGroup/InputGroup';
import { InputSizeVariant } from './RawInput';

export interface IProps
  extends Omit<InputGroupInput, 'type' | 'value' | 'onChange'> {
  wrapperClassName?: string;
  value: number;
  onChange?: (quantity: number) => void;
  increment?: number;
  min?: number;
  max?: number;
}

const IncrementableNumberInput: FC<IProps> = (props) => {
  const {
    wrapperClassName,
    value,
    increment = 1,
    min,
    max,
    onChange,
    onBlur,
    ...rest
  } = props;

  const [manualQuantity, setManualQuantity] = useState(value.toString());

  useEffect(() => {
    setManualQuantity(value.toString());
  }, [value]);

  const validateNewQuantity = (newQuantity: number) => {
    if (typeof min === 'number' && newQuantity < min) {
      return 'TOO_LOW';
    }

    if (typeof max === 'number' && newQuantity > max) {
      return 'TOO_HIGH';
    }

    return 'VALID';
  };

  const handleIncrement = (increment: number) => () => {
    const newValue = value + increment;

    switch (validateNewQuantity(newValue)) {
      case 'TOO_LOW':
        if (typeof min === 'number') {
          onChange?.(min);
        }
        break;
      case 'TOO_HIGH':
        if (typeof max === 'number') {
          onChange?.(max);
        }
        break;
      case 'VALID':
        onChange?.(newValue);
        break;
    }
  };

  const handleManualQuantityChange = (newQuantity: number) => {
    switch (validateNewQuantity(newQuantity)) {
      case 'TOO_LOW':
      case 'TOO_HIGH':
        setManualQuantity(value.toString());
        break;
      case 'VALID':
        onChange?.(newQuantity);
        break;
    }
  };

  return (
    <>
      <InputGroup
        className={wrapperClassName}
        sizeVariant={InputSizeVariant.SM}
      >
        <InputGroup.Button
          variant={EBtnVariant.White}
          onClick={handleIncrement(-increment)}
          disabled={typeof min === 'number' && value <= min}
          aria-label={`Decrement by ${increment}`}
        >
          <UntitledIcon name="minus.3" className="h-4 w-4" />
        </InputGroup.Button>
        <InputGroup.Input
          type="number"
          inputClassName="pl-form-input--number text-center"
          value={manualQuantity}
          onChange={setManualQuantity}
          onBlur={(e) => {
            onBlur?.(e);
            handleManualQuantityChange(Number(e.target.value));
          }}
          {...rest}
        />
        <InputGroup.Button
          variant={EBtnVariant.White}
          onClick={handleIncrement(increment)}
          disabled={typeof max === 'number' && value >= max}
          aria-label={`Increment by ${increment}`}
        >
          <UntitledIcon name="plus.3" className="h-4 w-4" />
        </InputGroup.Button>
      </InputGroup>
    </>
  );
};

export default IncrementableNumberInput;
