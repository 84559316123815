import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import emailTemplateKeys from '@/api/queries/emailTemplates/keyFactor';
import { graphql } from '@/gql';
import { GetEmailTemplatesInput } from '@/gql/graphql';

const GET_EMAIL_TEMPLATES = graphql(`
  query GetEmailTemplates($input: GetEmailTemplatesInput!) {
    emailTemplates(input: $input) {
      id
      name
      type
    }
  }
`);

const useGetEmailTemplates = (input: GetEmailTemplatesInput) => {
  return useQuery({
    queryKey: emailTemplateKeys.emailTemplates(input),
    queryFn: () => {
      return gqlClient.request(GET_EMAIL_TEMPLATES, { input });
    },
  });
};

export default useGetEmailTemplates;
