import { MaterialsListMaterial } from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import { TFormState } from '@/ui/components/createPurchaseOrderSidebar/CreatePurchaseOrderSidebar';
import {
  MaterialCardPropsWithTransition,
  MaterialCardWithTransition,
} from '@/ui/components/materialCard/MaterialCard';
import SupplierSelectionControl from '@/ui/components/supplierSelectionControl/SupplierSelectionControl';
import { parsePreferredFulfillment } from '@/ui/pages/dealPage/components/MaterialsPanel/LiveAvailabilityMaterialSupplierBadge/fulfillment';
import LiveBranchStockMaterialSupplierBadge from '@/ui/pages/dealPage/components/MaterialsPanel/LiveAvailabilityMaterialSupplierBadge/LiveBranchStockMaterialSupplierBadge';
import MaterialSuppliersBadges from '@/ui/pages/dealPage/components/MaterialSuppliersBadges';
import Conditional from '@payaca/components/conditional/Conditional';
import List from '@payaca/components/list/List';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';
import Field from '@payaca/components/plField/Field';
import { clstx } from '@payaca/components/utils';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useScroll } from 'react-use';

export interface IProps {
  formStage: 'MATERIAL_LIST_MATERIALS' | 'PURCHASE_ORDER_DETAILS';
  materialListMaterials: MaterialsListMaterial[];
  onAction: () => void;
  actionDisabled?: boolean;
}

const MaterialsToOrderList: FC<IProps> = (props) => {
  const {
    formStage,
    materialListMaterials,
    onAction,
    actionDisabled = false,
  } = props;

  /**
   * state
   */
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const { y: scrollY } = useScroll({ current: scrollRef });

  /**
   * use form methods
   */
  const formMethods = useFormContext<TFormState>();
  const selectedSupplierId = formMethods.watch('supplierId');
  const selectedMaterials = formMethods.watch('materials');

  const allAvailableSupplierIds = materialListMaterials.flatMap(
    (materialListMaterial) => {
      return materialListMaterial.materialsListMaterial.material.suppliedBy.map(
        (suppliedBy) => suppliedBy.supplier.id
      );
    }
  );

  console.log('allAvailableSupplierIds', allAvailableSupplierIds);

  const hasBottomScrollShadow = !(
    scrollRef &&
    scrollRef.scrollHeight - scrollRef.scrollTop === scrollRef.clientHeight
  );

  const actionButtonDisabled = actionDisabled || selectedMaterials.length === 0;

  return (
    <div
      className={clstx(
        'z-10 overflow-auto flex flex-col h-full transition-colors duration-700',
        formStage === 'MATERIAL_LIST_MATERIALS' && 'bg-gray-50',
        formStage === 'PURCHASE_ORDER_DETAILS' && 'bg-white'
      )}
      ref={setScrollRef}
    >
      <div
        className={clstx(
          'p-3.5 z-10 bg-white transition-colors duration-700 sticky top-0',
          formStage === 'MATERIAL_LIST_MATERIALS' && 'bg-gray-50',
          formStage === 'PURCHASE_ORDER_DETAILS' && 'bg-white',
          scrollY > 0 && 'shadow'
        )}
      >
        <Field className="mb-2.5">
          <Field.Label>Supplier you wish to order from</Field.Label>

          <Controller
            control={formMethods.control}
            name="supplierId"
            render={({ field: { onChange, value } }) => {
              return (
                <SupplierSelectionControl
                  canChangeSupplier={formStage === 'MATERIAL_LIST_MATERIALS'}
                  selectedSupplierId={value}
                  availableSupplierIds={allAvailableSupplierIds}
                  onChange={(newSupplierId) => {
                    if (newSupplierId) {
                      const filteredMaterialListMaterials =
                        materialListMaterials.filter((materialListMaterial) => {
                          return materialListMaterial.materialsListMaterial.material.suppliedBy.some(
                            (suppliedBy) =>
                              suppliedBy.supplier.id ===
                              newSupplierId.toString()
                          );
                        });

                      formMethods.setValue(
                        'materials',
                        filteredMaterialListMaterials.map(
                          (filteredMaterialListMaterial) => ({
                            ...filteredMaterialListMaterial,
                            purchaseOrderQuantity:
                              filteredMaterialListMaterial.materialQuantity,
                          })
                        )
                      );
                    } else {
                      formMethods.setValue('materials', []);
                    }

                    onChange(newSupplierId || undefined);
                  }}
                />
              );
            }}
          />
        </Field>

        <Conditional condition={selectedMaterials.length !== 0}>
          <div className="flex justify-between items-center">
            <h4>Materials to include on order</h4>

            <Conditional condition={formStage === 'PURCHASE_ORDER_DETAILS'}>
              <Button
                variant={EBtnVariant.LinkInline}
                disabled={actionButtonDisabled}
                onClick={actionButtonDisabled ? undefined : onAction}
              >
                Edit list
              </Button>
            </Conditional>
          </div>
        </Conditional>
      </div>

      <List
        className="px-3.5"
        items={selectedMaterials}
        item={(material) => {
          let materialCardProps: MaterialCardPropsWithTransition = {
            show: material.purchaseOrderQuantity > 0,
            afterLeave: () => {
              formMethods.setValue(
                'materials',
                selectedMaterials.filter(
                  (selectedMaterial) =>
                    selectedMaterial.purchaseOrderQuantity > 0
                )
              );
            },
            name: material.materialsListMaterial.material.name,
            price:
              material.materialsListMaterial.material.suppliedBy[0]?.price
                .unitPriceExcTax,
            thumbnailUrl:
              material.materialsListMaterial.material.image?.thumbnailUrl ||
              undefined,
          };

          if (formStage === 'MATERIAL_LIST_MATERIALS') {
            materialCardProps = {
              ...materialCardProps,
              quantity: material.purchaseOrderQuantity,
              maxQuantity: material.materialQuantity,
              onChangeQuantity: (newQuantity) => {
                if (
                  newQuantity > material.materialQuantity ||
                  newQuantity < 0
                ) {
                  return;
                }

                formMethods.setValue(
                  'materials',
                  selectedMaterials.map((selectedMaterial) => {
                    return selectedMaterial.id === material.id
                      ? {
                          ...selectedMaterial,
                          purchaseOrderQuantity: newQuantity,
                        }
                      : selectedMaterial;
                  })
                );
              },
            };
          }

          return (
            <MaterialCardWithTransition
              key={material.id}
              childrenClassName={clstx(
                formStage === 'PURCHASE_ORDER_DETAILS' &&
                  'w-full flex justify-between'
              )}
              {...materialCardProps}
            >
              <MaterialSuppliersBadges
                suppliers={material.materialsListMaterial.material.suppliedBy.map(
                  (s) => ({
                    id: s.supplier.id,
                    name: s.supplier.name,
                  })
                )}
                preferredFulfillment={parsePreferredFulfillment(
                  material.materialsListMaterial.preferredFulfillment
                )}
                LiveAvailabilityMaterialSupplierBadge={
                  LiveBranchStockMaterialSupplierBadge
                }
                maxSuppliersToShow={1}
              />

              <Conditional condition={formStage === 'PURCHASE_ORDER_DETAILS'}>
                <p>Qty: {material.purchaseOrderQuantity}</p>
              </Conditional>
            </MaterialCardWithTransition>
          );
        }}
      />

      <Conditional condition={!selectedSupplierId}>
        <EmptyState
          className="mb-auto"
          text="Start by selecting a Supplier"
          iconName="arrow-block-up.3"
        />
      </Conditional>

      <Conditional
        condition={!!selectedSupplierId && selectedMaterials.length === 0}
      >
        <EmptyState className="my-auto" text="No Materials on order" />
      </Conditional>

      <Conditional condition={formStage === 'MATERIAL_LIST_MATERIALS'}>
        <div
          className={clstx(
            'p-3.5 z-10 transition-colors duration-700 sticky bottom-0 shadow bg-gray-50',
            hasBottomScrollShadow && 'shadow-flipped-lg',
            selectedSupplierId && selectedMaterials.length > 0 && 'mt-auto'
          )}
        >
          <Button
            className="w-full"
            disabled={actionButtonDisabled}
            variant={EBtnVariant.Solid}
            onClick={actionButtonDisabled ? undefined : onAction}
          >
            Proceed
          </Button>
        </div>
      </Conditional>
    </div>
  );
};

export default MaterialsToOrderList;
